@import "../form/clearhaus/ClearhausOnboardingForm";

.clearhaus-onboarding-message {
    $indent-left: $clearhaus-indent-normal;
    $indent-left-tablet: 100px;

    font-size: 16px;

    .clearhaus-onboarding-message__main-message {
        display: flex;

        .clearhaus-onboarding-message__circle {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-basis: $indent-left;
        }

        .clearhaus-onboarding-message__text {
            max-width: $clearhaus-base-fields-width;
        }
    }

    .clearhaus-onboarding-message__left-button {
        @include clearhaus-footer-left-button-styles;
    }

    .clearhaus-onboarding-message__main-button {
        @include clearhaus-footer-main-button-styles;
    }

    .clearhaus-onboarding-message__message-after-underline {
        font-size: 14px;
        font-weight: 300;
        padding-left: $indent-left;
        max-width: $clearhaus-base-fields-width + $indent-left;
    }

    @include clearhaus-breakpoint-tablet {
        .clearhaus-onboarding-message__main-message {
            .clearhaus-onboarding-message__circle {
                min-width: $indent-left-tablet;
                flex-basis: unset;
            }

            .clearhaus-onboarding-message__text {
                max-width: fit-content;
            }
        }

        .clearhaus-onboarding-message__message-after-underline {
            padding-left: $indent-left-tablet;
            max-width: fit-content;
        }
    }
}