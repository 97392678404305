@import "./ClearhausOnboardingForm.scss";

.clearhaus-update-info-form {
    @include clearhaus-fields-width-base;

    .clearhaus-update-info-form__go-back-button {
        @include clearhaus-footer-left-button-styles;
    }

    .clearhaus-update-info-form__submit-button {
        @include clearhaus-footer-main-button-styles;
    }

    .clearhaus-update-info-form__company-section,
    .clearhaus-update-info-form__bank-account-section {
        .alert-danger {
            margin-left: $clearhaus-documentation-left-margin;
            @include clearhaus-breakpoint-tablet {
                margin-left: 0;
            }
        }
    }
}