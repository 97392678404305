@import "../../styles/partials/variables";

.merchant-offer-steps {
    position: relative;

    padding-bottom: 30px;

    @media (max-width: $breakpoint-large) {
        padding-bottom: 50px;
    }

    .merchant-offer-steps__line {
        position: absolute;
        z-index: 0;
        top: 16px;
        height: 3px;
        width: 100%;
        background-color: #dbe3eb;

        @media (max-width: $breakpoint-mobile) {
            height: 2px;
            top: 12px;
        }
    }

    .merchant-offer-steps__steps-container {
        display: flex;
        justify-content: space-between;
        padding: 0 80px;

        @media (max-width: $breakpoint-tables) {
            padding: 0 40px;
        }

        @media (max-width: $breakpoint-mobile) {
            padding: 0 20px;
        }
    }

    .merchant-offer-steps__step {
        position: relative;

        &.merchant-offer-steps__step--can-select {
            cursor: pointer;
        }

        .merchant-offer-steps__step-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 700;
            height: 35px;
            width: 35px;
            border-radius: 35px;
            background-color: #dbe3eb;
            color: #829eba;

            @media (max-width: $breakpoint-mobile) {
                height: 25px;
                width: 25px;
                font-size: 16px;
            }
        }
        .merchant-offer-steps__step-title {
            color: #829eba;
            position: absolute;
            white-space: nowrap;
            font-size: 14px;
            transform: translate(calc(-50% - 35px), 0);
            left: calc(50% + 35px);
            margin-top: 3px;

            @media (max-width: $breakpoint-large) {
                white-space: inherit;
                text-align: center;
            }

            @media (max-width: $breakpoint-mobile) {
                transform: translate(calc(-50% - 25px), 0);
                left: calc(50% + 25px);
            }
        }

        &.merchant-offer-steps__step--active {
            .merchant-offer-steps__step-circle {
                background-color: rgba(15, 76, 138, 0.9);
                font-weight: 700;
                color: white;
            }
            .merchant-offer-steps__step-title {
                color: #275e95;
            }
        }
    }
}
