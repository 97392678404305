.swish-details {
    display: flex;
    margin-top: 40px;
    padding: 0 15%;
    align-items: center;
    flex-direction: column;

    .swish-id-input {
        width: 100%;
    }
}
