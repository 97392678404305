.single-order-container {
    padding: 50px !important;

    .heading {
        font-size: 18px;
        font-weight: 600;
    }

    .order-info {
        .payment-link-notice {
            flex-basis: 100%;
            font-size: 14px;
            font-weight: 500;
            text-align: right;
            color: #607995;
            font-style: italic;
            margin-top: 10px;
        }

        .payment-link-input-container {
            margin-top: 35px;
            margin-bottom: 20px;
            span {
                font-size: 16px;
            }
        }

        .webshopFeature-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fafafa;
            padding: 10px;
            font-size: 1.2em;
            margin-bottom: 20px;

            a {
                color: #1a496d;
                text-decoration: underline;
            }

            img {
                max-width: 35px;
                margin-right: 2px;
            }

            .plussell-ids,
            .returnsell-ids {
                display: flex;
                flex-direction: column;

                span {
                    margin: 6px;
                    text-align: right;
                }
            }
        }

        .actions-container {
            display: flex;
            flex-direction: column;

            .swiipe-spinner-container {
                width: 100px;
                margin-left: auto;

                button {
                    width: 100px;
                    background: transparent !important;
                    font-size: 14px;
                    margin-top: 10px;

                    color: #797676;
                    border-color: #797676;
                }

                .capture-button {
                    color: #23638e;
                    border-color: #23638e;
                }
            }
        }
    }

    .payment-info {
        font-size: 15px;
        background: #f2f2f27f;
        padding: 20px;
        padding-bottom: 10px;
        color: #797676;
        width: 400px;

        hr {
            margin: 10px 0;
        }

        div {
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
        }

        .report {
            font-size: 13px;
            font-weight: 300;

            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .method {
            img {
                max-height: 20px;
                max-width: 50px;
                margin-right: 5px;
                margin-top: -4px;

                &.credit-card {
                    width: 25px;
                }
            }
        }

        .card-type {
            img {
                width: 30px;
                margin-top: -4px;
                margin-right: 5px;
            }
        }
    }

    .transactions {
        margin-top: 30px;

        .date {
            min-width: 145px;
        }

        .tx-id {
            min-width: 110px;
        }

        .notes {
            width: 380px;
        }

        .error-details-btn {
            outline: none;
            border: none;
            background-color: transparent;
            color: #797676;
        }

        .error-details-btn:hover {
            cursor: pointer;
            text-decoration: underline;
            color: rgb(0, 86, 143);
        }
    }

    .customer-data {
        margin-top: 40px;

        .order-details {
            width: 100%;
            color: #838383;
            border: 1px solid #e1e1e1;
            padding: 20px;
            font-size: 16px;

            display: flex;

            div {
                margin-right: 17px;
            }

            span {
                display: block;
            }
        }
    }

    @media screen and (max-width: 960px) {
        .customer-data {
            .order-details {
                flex-direction: column;

                div {
                    margin-top: 20px;
                }
            }
        }
    }

    @media screen and (max-width: 870px) {
        .order-data {
            flex-direction: column;

            .order-info {
                margin-left: 0;
                margin-top: 20px;
                width: 400px;
            }

            .payment-info {
                width: 400px;
            }
        }
    }

    @media screen and (max-width: 870px) {
        .order-data {
            .order-info {
                width: 100%;
            }

            .payment-info {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 470px) {
        .customer-data {
            .order-details {
                display: flex;
                flex-direction: column;

                div {
                    margin-top: 15px;
                }
            }
        }
    }
}
