@import "../../styles/partials/variables";

.merchant-offer-intro {
    padding-top: 10px;
    font-size: 17px;
    font-weight: 400;
    color: #22303e;

    b {
        font-weight: 700;
    }

    .merchant-offer-intro__services-title {
        margin-top: 56px;
    }

    .merchant-offer-intro__services-overview {
        font-size: 16px;
        padding-left: 25px;
        margin-bottom: 24px;

        b {
            font-weight: 600;
        }

        li {
            line-height: 1.8;
        }
    }

    .merchant-offer-intro__services-desc {
        margin-bottom: 80px;
    }
}
