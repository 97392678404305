@import "../../styles//partials//all";

.accordion-with-children-component {
    $header-text-color: #797979;
    &.header-left {
        .accordion-with-children-component__header {
            text-align: left;
            p {
                width: 100%;
            }
        }
    }
    &.header-arrow-next-to {
        .accordion-with-children-component__header {
            justify-content: flex-start;
            p {
                width: inherit;
            }
        }
    }

    &.animate {
        .accordion-with-children-component__header {
            .arrow-container {
                .arrow {
                    -webkit-transition: all 0.1s linear;
                    -moz-transition: all 0.1s linear;
                    -o-transition: all 0.1s linear;
                    transition: all 0.1s linear;
                }
                &.down {
                    .arrow {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    }
                }
                &.up {
                    .arrow {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(-135deg);
                    }
                }
            }
        }
        .children {
            transition: max-height 0.45s linear;
            &.expanded {
                transition: max-height 0.45s linear;
            }
        }
    }

    .accordion-with-children-component__header {
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: $header-text-color;
        background-color: white;
        padding: 10px 0;
        align-items: center;
        font-weight: 300;

        &--section-title {
            font-size: 18px;
            font-weight: 600;
            color: $blue-main;
        }

        p {
            margin: 0 10px 0 0;
        }

        .arrow-container {
            .arrow {
                border: solid $header-text-color;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;

                width: 10px;
                height: 10px;

                -webkit-transition: all 0.1s linear;
                -moz-transition: all 0.1s linear;
                -o-transition: all 0.1s linear;
                transition: all 0.1s linear;
            }

            &.down {
                margin-top: -7px;

                .arrow {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                }
            }

            &.up {
                height: 18px;

                .arrow {
                    transform: rotate(-135deg);
                    -webkit-transform: rotate(-135deg);
                }
            }
        }
    }

    &.simplified {
        .accordion-with-children-component__header {
            color: #23638e !important;
            text-decoration: underline !important;
            text-transform: none !important;
            font-weight: normal !important;
        }
    }

    .children {
        max-height: 0;
        transition: max-height 0.45s linear;
        overflow: hidden;

        &.expanded {
            max-height: 10000px;
            transition: max-height 0.45s linear;
        }
    }
}
