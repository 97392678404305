@import "../../../styles//partials//all";

.swiipe-modal-header {
    justify-content: center;
    .modal-title {
        min-height: 10px;
    }
}

.errormessage-text {
    color: #1a496d;
    padding: 0.4rem;
}

.swiipe-modal-container {
    .modal-backdrop {
        opacity: 0.75;
    }

    .modal-body {
        #table-of-content {
            a {
                text-decoration: none;
                font-weight: inherit;
            }
        }

        a {
            text-decoration: none;
            color: $blue-header;
            font-weight: 600;

            &:hover {
                text-decoration: none;
                color: $blue-link-hover !important;
            }
        }
    }

    .close-cross {
        position: absolute;
        cursor: pointer;
        right: 20px;

        transform: scale(0.8);
    }

    &.no-borders-inside {
        .modal-header {
            border-bottom: none;
        }

        .modal-footer {
            border-top: none;
        }
    }

    &.no-border-top {
        .modal-header {
            border-bottom: none;
        }
    }

    &.full-size {
        .modal-body {
            padding: 0;
        }
        .modal-dialog {
            height: calc(100% - 3.5rem);
        }

        .modal-content {
            height: 100%;
        }
    }
}
