@import "../../styles/partials/all.scss";

.swiipe-fieldset-form-group {
    .alert-f2 {
        border-color: $gray-background;
        background-color: $gray-background;
        color: $blue-main;
        margin-bottom: 0;
        margin-top: 5px;
        padding: 8px 12px;

        span.icon {
            vertical-align: middle;
        }
    }

    /*Float lables styles*/
    &.sw__field,
    &.sw__field_6 {
        position: relative;
        vertical-align: top;
    }

    &.tight {
        margin-bottom: 0.5rem;
    }

    &.sw__field_6 {

        //flex: 0 0 50%;
        //max-width: 50%;
        .sw__field_label {
            &::after {
                left: $base--spacing;
            }
        }
    }

    .sw__field_label {
        &::after {
            user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            pointer-events: none;
            content: attr(data-value);
            position: absolute;
            display: block;
            top: $base--line-height * 0.5;
            left: $base--spacing * 0.5;
            color: $color--payment-gray;
            transition: 0.25s all ease;
            font-weight: 400;
        }

        &.active {
            &:after {
                top: 0;
                font-size: 10px;
            }
        }
    }
}