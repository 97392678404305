#sort-dropdown-box {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.5em;
    white-space: nowrap;

    span {
        margin-right: 10px;
        color: #7f7f7f;
    }

    select {
        max-width: 115px;
        font-size: 1.1em;
        padding: 0px 6px !important;
        height: 30px;
        margin-right: 15px;
        color: #7f7f7f;
    }

    .sort-dropdown-box__icon {
        height: 21px;
        width: 21px;
        cursor: pointer;

        &.asc {
            margin-left: 4px;
            margin-top: -3px;
        }

        &.desc {
            margin-top: 4px;
        }
    }
}
