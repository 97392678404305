.webshop-fields-container {
    display: flex;
    align-items: center;

    .delete-webshop-field-btn {
        color: #356497;
        font-size: 1.5em;
        margin-left: 5px;
        margin-top: 6px;
        cursor: pointer;

        &:active {
            font-weight: 600;
        }
    }
}

.mobilepay-notice {
    text-align: center;
    font-size: 12px;
    font-style: italic;
    color: #555555;
    margin-top: 10px;
}
