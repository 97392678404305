@import "../../scss/partials/_all.scss";

.modal-content {
    .footer-text {
        text-align: center;
        color: $gray-font;
        font-size: 0.9em;
    }

    .send-email {
        padding: 0px;
        border: none;
        opacity: 1;
        cursor: pointer;
        background-color: unset !important;
        height: auto;
        display: block;
        max-width: 270px;
        margin: 0 auto;

        .send-email-img {
            width: 100%;
            height: auto;
        }
    }

    .swiipe-spinner-container {
        display: block;
    }
}
