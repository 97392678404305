.payment-link-error-modal {
    .modal-title {
        color: #910012;
        font-size: 17px;
        font-weight: 400;
        padding: 0.4rem;
    }

    .buttons {
        display: flex;
        justify-content: space-around;

        button {
            max-width: 200px;
        }
    }
}
