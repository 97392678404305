.merchant-dashboard-value-in-text {
    .item {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-weight: 700;
        }

        .data-compare {
            margin-left: 10px;
        }
    }
}
