.add-apikey-form {
    margin-top: 40px;

    .add-apikey-form__title {
        text-align: center;
        font-size: 1.5em;
    }

    .add-apikey-form__desc {
        margin: 20px;
    }

    .add-apikey-form__input-title {
        margin-bottom: 10px;
        font-size: 1.3em;
    }

    .add-apikey-form__save-button {
        margin-top: 20px;
    }

    .add-apikey-form__custom-integrator-name {
        margin-top: 10px;
    }

    .add-apikey-form__custom-integrator-name-error {
        color: red;
    }
}
