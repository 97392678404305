.checkmark-circle {
    .enabled {
        --color: #7ac142;
        --fill: #7ac142;
        --checkmark-color: #fff;
    }

    .disabled {
        --color: #dedede;
        --fill: #dedede00;
        --checkmark-color: #dedede;
    }

    &__size {
        &-medium {
            $medium-size: 30px;
            width: $medium-size;

            .checkmark {
                width: $medium-size;
                height: $medium-size;
            }
        }

        &-big {
            $medium-size: 60px;
            width: $medium-size;

            .checkmark {
                width: $medium-size;
                height: $medium-size;
            }
        }
    }

    .checkmark {
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: var(--checkmark-color);
        stroke-miterlimit: 10;
        box-shadow: inset 0px 0px 0px var(--color);
        animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
    }

    .checkmark_circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: var(--color);
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .checkmark_check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes scale {

        0%,
        100% {
            transform: none;
        }

        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }

    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px var(--fill);
        }
    }
}