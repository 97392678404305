@import "../../form/FormBaseStyles.scss";

.acquiring-contact-fields {
    .acquiring-contact-fields__field {
        max-width: $form-base-fields-width;
    }

    .acquiring-contact-fields__email-row-container {
        display: flex;

        .acquiring-contact-fields__field {
            flex: 0 1 $form-base-fields-width;
        }

        .acquiring-contact-fields__email-row-container-hint-column-container {
            flex: 1 1 0px;

            .acquiring-contact-fields__email-row-container-hint-column {
                margin-right: $form-hints-margin-right;
                float: right;
            }
            .acquiring-contact-fields__email-row-container-hint-column-title {
                max-width: $form-hints-title-width;
            }
        }

        @include form-hint-breakpoint-laptop {
            flex-direction: column;

            .acquiring-contact-fields__field {
                flex: 1 1 0;
            }
            .acquiring-contact-fields__email-row-container-hint-column-container {
                .acquiring-contact-fields__email-row-container-hint-column {
                    float: none;
                    margin-bottom: 1.5em;
                }
                .acquiring-contact-fields__email-row-container-hint-column-title {
                    max-width: inherit;
                }
            }
        }
    }
}
