#applepay-onboarding-container {
    .applepay-form-head {
        text-align: center;
        margin: 20px 0 30px 0;

        .applepay-logo {
            max-width: 80px;
            border: #414141;
            border-style: solid;
            border-radius: 10px;
            border-width: 2px;
        }

        .swiipe-payments {
            width: 170px;
        }
    }
}
