@import "../../../scss/partials/_colors.scss";
.merchant-dashboard-tile-no-data {
    display: flex;
    flex-direction: column;

    .no-data-text {
        margin-left: 20px;
        margin-top: 20px;
        font-size: 13px;
        
        &.text-disabled {
            opacity: 0.3;
        }
    }
}
