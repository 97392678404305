.merchant-accounts-page {
    .close-account {
        span {
            cursor: pointer;
            color: #797676;
            font-size: 16px;
            font-weight: 300;

            border-bottom: 1px dotted #797676;
        }
    }

    @media screen and (max-width: 400px) {
        .close-account {
            text-align: center;

            span {
                font-size: 14px;
            }

            .col-3 {
                display: none;
            }

            .col-9 {
                max-width: 100% !important;
                flex: 0 0 100%;
            }
        }
    }
}
