.simple-table-container {
    .simple-table-container-title {
        font-size: 18px;
        font-weight: 600;
    }
    .simple-table {
        width: 100%;
        font-size: 1.2em;

        .simple-table__table-header-cell {
            height: 50px;
            font-size: 0.9em;
        }

        tr {
            &:nth-child(1) {
                border: none;
            }

            height: 50px;
            border-top: 1px solid #e8e8e8;
        }

        .simple-table__table-header-cell,
        .simple-table__table-row-cell {
            min-width: 60px;
        }

        tr:nth-child(1) {
            .simple-table__table-row-cell {
                white-space: nowrap;
            }
        }

        .simple-table__table-row-cell {
            font-weight: 300;
        }
    }

    @media screen and (max-width: 960px) {
        .simple-table {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            .simple-table__table-header-cell,
            .simple-table__table-row-cell {
                min-width: 80px;
            }
        }
    }
}
