.winback-onboarding-container {
    .winback-form-head {
        text-align: center;
        margin: 20px 0 30px 0;

        .winback-logo {
            width: 170px;
        }
    }

    .winback-info {
        font-size: 1.15em;
    }

    .input-legend {
        color: #767679;
        font-size: 1.2em;
    }

    .checkbox {
        color: #767679;
        font-size: 1.2em;
    }

    .winback-form {
        margin-top: 40px;
        padding: 0 18%;

        [name*="useWebshopRedirect"] + * {
            display: inline;
            align-self: center;
        }

        .submit-btn {
            max-width: 280px;
            margin: 0 auto;
        }
    }

    .checkbox-container {
        position: relative;

        .see {
            position: absolute;
            top: 2px;
            right: 0px;
        }
    }

    .order-price-limits {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        & > * {
            flex: 1;
        }

        .order-price-limits__currency-select {
            width: auto;
            flex: 0 1;

            @media screen and (max-width: 750px) {
                flex: 0 0 100%;
            }
        }
    }

    @media screen and (max-width: 685px) {
        .winback-form {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 450px) {
        .winback-form {
            margin-top: 40px;
            padding: 0 5%;

            .submit-btn {
                max-width: 240px;
            }
        }
    }

    @media screen and (max-width: 380px) {
        .winback-form {
            padding: 0;

            .submit-btn {
                max-width: 200px;
            }
        }
    }

    .winback-back-link {
        margin-bottom: 25px;
        cursor: pointer;
        text-align: center;

        a {
            color: #23638e;
            text-decoration: none;
        }
    }
}
