@import "../../../../scss/partials/all";

.merchant-dashboard-filter-container {
    display: flex;
    align-items: center;

    &__title {
        color: $gray-font;
        font-size: 16px;
        text-wrap: nowrap;
    }

    &__filter {
        display: flex;
    }

    &__value {
        padding-left: 10px;
        color: $gray-font;
    }
}