.merchant-contact-info-preview {
    .internal-comment {
        background: #f2f2f2;
        padding: 10px;
        .internal-comment__author {
            font-style: italic;
            font-size: 0.8em;
        }
        .internal-comment__created {
            font-style: italic;
            font-size: 0.8em;
        }
    }
}
