.modal-dialog {
    color: #0e3251;
    .modal-header {
        justify-content: center;

        .modal-title {
            text-align: center;

            .subtitle {
                font-size: 0.75em;
            }
        }
    }
}