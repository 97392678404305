@import "../../scss/partials/all";

#cvr-not-found-container {
    .message-container {
        width: 560px !important;
        background-color: white;
        margin: auto;
        margin-bottom: 80px;
        padding: 50px 70px;
        border-radius: 0px;
    }

    .alert-container {
        p {
            font-size: 1.2em;
            color: #797979;
            font-weight: 300;

            &.text-larger {
                font-size: 1.35em;
            }
        }

        .company-info {
            border-color: $gray-background;
            background-color: $gray-background;
            color: #495057;
            margin-top: 5px;
            margin-bottom: 0;
            padding: 10px 15px;
            font-size: 1.1em;
        }
    }
}

@media screen and (max-width: 720px) {
    #main-container {
        .onboarding-container {
            width: auto !important;
        }
    }
}

@media screen and (max-width: 420px) {
    #main-container {
        .onboarding-container {
            width: auto !important;
            padding: 35px 15px;
        }
    }
}
