.send-email-box {
    display: flex;
    justify-content: center;

    .send-email-box__already-sent {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        justify-content: space-around;
        font-size: 14px;

        img {
            width: 20px;
            height: 20px;
        }
    }

    .send-email-box__send-button-container {
        width: 100%;
        
        button {
            height: 40px;
        }
    }
}