.swish-cert-step {
    .swish-cert-form {
        display: flex;
        padding: 0 15%;
        flex-direction: column;

        .submit-btn {
            max-width: 280px;
            width: 50%;
            align-self: center;
        }
    }

    .detailed-guide-section {
        margin-bottom: 1em;

        display: flex;
        flex-direction: column;
        align-items: center;

        #detailed-guide-link {
            color: #555555;
            cursor: pointer;
            text-decoration: underline;
            width: fit-content;
        }

        #detailed-guide-link:hover {
            color: #252525;
        }

        .detailed-guide-steps {
            margin-bottom: 3em;
            ol > li {
                margin: 1rem 0 0 0;
                text-indent: -2em;
                list-style-type: none;
                counter-increment: item;
                text-align: justify;
            }

            ol > li:before {
                display: inline-block;
                width: 1.5em;
                padding-right: 0.5em;
                font-weight: bold;
                text-align: right;
                content: counter(item) ".";
            }
        }
    }

    .swish-certificate-section {
        margin-top: 3em;
    }

    @media screen and (max-width: 685px) {
        .swish-cert-form {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 475px) {
        .swish-cert-form {
            margin-top: 40px;
            padding: 0 5%;

            .submit-btn {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 380px) {
        .swish-cert-form {
            padding: 0;
        }
    }
}
