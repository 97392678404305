@import "../ClearhausOnboardingForm.scss";

.person-header {
    position: absolute;
    margin-top: 5px !important;
    font-weight: 600;
    font-size: 1.2em;

    @include clearhaus-breakpoint-tablet {
        position: inherit;
    }
}

.clearhaus-person-review {
    .clearhaus-person-review__fields {
        margin-left: $clearhaus-documentation-left-margin;
    }

    @include clearhaus-breakpoint-tablet {
        .clearhaus-person-review__fields {
            margin-left: 0;
        }
    }
}

.clearhaus-people-inputs {
    .clearhaus-people-inputs__person-fields {
        margin-left: $clearhaus-documentation-left-margin;
    }
    
    .person-delete-btn {
        cursor: pointer;
        vertical-align: middle;
    }

    @include clearhaus-breakpoint-tablet {
        .clearhaus-people-inputs__person-fields {
            margin-left: 0;
        }
    }
}

.clearhaus-documentation-fields {
    .clearhaus-documentation-fields__submitted-review,
    .clearhaus-documentation-fields__ownership-structure,
    .clearhaus-documentation-fields__files {
        margin-left: $clearhaus-documentation-left-margin;
    }

    hr {
        width: $clearhaus-base-fields-width + $clearhaus-documentation-left-margin;
        margin-left: 0;
    }

    .clearhaus-documentation-fields__info-hint {
        float: right;
        margin-right: $clearhaus-hints-margin-right;
        margin-bottom: 1.5em;

        .clearhaus-documentation-fields__hint-title {
            width: $clearhaus-hints-title-width;
        }
    }

    .clearhaus-documentation-fields__people-inputs {
        &--with-margin-top {
            margin-top: 32px;
        }
    }

    @include clearhaus-hint-breakpoint-laptop {
        .clearhaus-documentation-fields__info-hint {
            float: unset;
            margin-left: $clearhaus-documentation-left-margin;
            margin-bottom: 10px;
            max-width: $clearhaus-base-fields-width;
    
            .clearhaus-documentation-fields__hint-title {
                flex-grow: 1;
            }
        }
    }

    @include clearhaus-breakpoint-tablet {
        hr {
            width: auto;
        }

        .clearhaus-documentation-fields__info-hint {
            margin-left: 0;
        }

        .clearhaus-documentation-fields__submitted-review,
        .clearhaus-documentation-fields__ownership-structure,
        .clearhaus-documentation-fields__files {
            margin-left: 0;
        }
    }
}