@import "../../../styles/partials/all";

.comp-details {
    font-size: 14px;

    .header {
        color: $gray-font-dark;
    }

    .info {
        border-color: $gray-background;
        background-color: $gray-background;
        color: #495057;
        margin-bottom: 20px;
        margin-top: 5px;
        padding: 10px 15px;
    }
}
