.options-filter-box {
    display: flex;

    .input-title {
        font-weight: 600;
    }

    input {
        padding: 0 5px;
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1120px) {
    .options-filter-box {
        flex-wrap: wrap;
        justify-content: center;
        .children-dropdown-container {
            width: 115px;
            display: inline-block;
            margin-bottom: 5px;

            .dropdown-main {
                display: inline-block !important;
            }
        }
    }
}
