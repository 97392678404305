@import "../../../scss/partials/_all.scss";

$gray-light: #f9f9f9;

#signup {
    .btn-skip {
        background: none;
        border: none;
        border-bottom: 1px dotted $gray-font;
        color: $gray-font;
        padding: 0;
        font-size: 14px !important;
        text-transform: none !important;

        &:hover {
            color: #b3b3b3;
        }
    }

    .form-control[disabled] {
        background: $gray-light;
        color: $gray-font;
    }

    @media screen and (max-width: $breakpoint-mobile-m1) {
        .mb-fix {
            margin-bottom: 1rem;
        }
    }
}
