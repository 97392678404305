.api-key-container {
    align-items: unset !important;

    .desc-bottom {
        display: block;
        margin-top: 5px;
    }

    .scope {
        padding: 15px 0 0 15px;
    }
}
