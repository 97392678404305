@import "../../../form/clearhaus/ClearhausOnboardingForm.scss";

.clearhaus-update-bank-fields {
    .clearhaus-update-bank-fields__field-pair {
        margin-left: $clearhaus-documentation-left-margin;
    }

    hr {
        width: $clearhaus-base-fields-width + $clearhaus-documentation-left-margin;
        margin-left: 0;
    }

    .clearhaus-update-bank-fields__explanation-hint {
        position: absolute;
        right: 35px;
        top: 35px;

        .clearhaus-update-bank-fields__hint {
            width: 200px;
        }
        
        @include clearhaus-hint-breakpoint-laptop {
            position: initial;
            .clearhaus-update-bank-fields__hint {
                width: unset;
                margin-left: $clearhaus-documentation-left-margin;
                max-width: $clearhaus-base-fields-width;
            }
        }
    }

    @include clearhaus-breakpoint-tablet {
        .clearhaus-update-bank-fields__field-pair,
        .clearhaus-update-bank-fields__explanation-hint .clearhaus-update-bank-fields__hint {
            margin-left: 0;
        }

        hr {
            width: auto;
        }
    }
}