@import "../../scss/partials/variables";

.children-dropdown-container {
    .box-styled {
        color: #797676;
        border: 1px solid #d7d7d7;
        border-radius: $input-border-radius;
        background: white;
        padding: 4px 6px;
        width: 115px;

        .selected {
            color: #1a486c;
            font-weight: bold;
            font-size: 0.8em;
            width: 80px;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 0.16em 0;

            &.small {
                font-size: 1em;
                padding: 0 0;
            }
        }

        &.dropdown-main {
            cursor: pointer;
            position: relative;

            &.disabled {
                background-color: #e9ecef;
                cursor: default;
            }

            img {
                position: absolute;
                height: 5px;
                top: 50%;
                transform: translateY(-50%);
                right: 6px;
            }
        }

        &.child {
            position: absolute;
            z-index: 999;

            -moz-box-shadow: 5px 5px 5px #00000059;
            -webkit-box-shadow: 5px 5px 5px #00000059;
            box-shadow: 5px 5px 5px #00000059;
        }
    }
}
