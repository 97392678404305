.bookkeeping-main-container {
    padding: 40px 40px;

    .disclamer {
        border-color: #f2f2f2;
        background-color: #f2f2f2;
        color: #1a496d;
        margin-bottom: 0;
        padding: 8px 25px;
    }

    .bookkeeping-form {
        padding: 0 85px;

        .select-acquirer-heading {
            margin-top: 15px;
            flex: 1 0 100%;
            font-weight: 300;
            font-size: 1.1em;
        }
    }

    .bookkeeping-submit {
        display: flex;
        justify-content: space-around;
        margin-top: 35px;

        button {
            height: 40px;
            font-size: 0.95em;
            max-width: 260px;
            min-width: 260px;
        }
    }

    .header-text {
        font-size: 1.2em;
        font-weight: 600;
    }

    @media screen and (max-width: 820px) {
        .bookkeeping-submit {
            flex-direction: column;

            .swiipe-spinner-container {
                width: fit-content;
                margin: 0 auto;
            }
        }
    }

    @media screen and (max-width: 760px) {
        .bookkeeping-form {
            padding: 0 30px;
        }

        .bookkeeping-form {
            .swiipe-spinner-container {
                width: auto;
                margin: 0 auto;

                button {
                    margin: 0 auto;
                }
            }
        }
    }

    @media screen and (max-width: 670px) {
        padding: 40px 20px;

        .bookkeeping-form {
            padding: 0 10px;
        }
    }

    @media screen and (max-width: 430px) {
        padding: 40px 5px;

        .bookkeeping-form {
            padding: 0 10px;
        }
    }

    @media screen and (max-width: 375px) {
        .bookkeeping-submit {
            .swiipe-spinner-container {
                min-width: unset;
                margin: 0;
                display: block;
                width: 100%;
            }

            button {
                height: 50px;
                min-width: unset;
            }
        }
    }
}
