@import "../form/clearhaus/ClearhausOnboardingForm";

.clearhaus-onboarding-footer {
    display: flex;

    .clearhaus-onboarding-footer__left {
        flex-basis: $clearhaus-indent-normal;
        display: flex;
        justify-content: center;
    }
    
    .clearhaus-onboarding-footer__middle {
        flex-basis: $clearhaus-base-fields-width;
    }
    
    .clearhaus-onboarding-footer__right {
        flex-grow: 1;
    }

    @include clearhaus-breakpoint-laptop {
        flex-wrap: wrap;

        .clearhaus-onboarding-footer__right {
            margin-top: 10px;
            flex-basis: 100%;
        }
    }

    @include clearhaus-breakpoint-tablet {
        flex-direction: column;

        .clearhaus-onboarding-footer__left,
        .clearhaus-onboarding-footer__middle,
        .clearhaus-onboarding-footer__right {
            flex-basis: auto !important;
            justify-content: center;
            margin-bottom: 10px;
        }

        .clearhaus-onboarding-footer__right {
            margin-top: 0;
        }
    }
}