.payment-link-customize-modal {
    font-size: 0.8em;
    color: #0e3251;

    .modal-pl-custom {
        max-width: 710px !important;
    }

    .modal-body {
        padding: 0;
        margin: 0 0 30px;
    }
}
