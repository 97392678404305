@import "./FormBaseStyles.scss";

.form-input-column-with-info-column {
    display: flex;

    &__input-column {
        flex: 0 1 $form-base-fields-width;
    }
    .form-input-column-with-info-column__info-column-container {
        flex: 1 1 0px;

        &.form-input-column-with-info-column__info-column-container--restrict-width {
            .form-input-column-with-info-column__info-column {
                margin-right: $form-hints-margin-right;
                max-width: $form-hints-title-width + 21px;
            }
            &.form-input-column-with-info-column__info-column-container--narrow-width {
                .form-input-column-with-info-column__info-column {
                    max-width: $form-hints-title-width;
                }
            }
        }

        .form-input-column-with-info-column__info-column {
            float: right;
        }
    }

    @include form-hint-breakpoint-laptop {
        flex-direction: column;

        .form-input-column-with-info-column__input-column {
            flex: 1 1 0;
            max-width: $form-base-fields-width;
        }
        .form-input-column-with-info-column__info-column-container {
            &.form-input-column-with-info-column__info-column-container--restrict-width {
                .form-input-column-with-info-column__info-column {
                    margin-bottom: 1.5em;
                    max-width: inherit !important;
                }
            }
            .form-input-column-with-info-column__info-column {
                float: none;
            }
        }
    }
}
