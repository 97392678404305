.list-of-messages {
    background-color: #f2f2f2;
    color: #333333;
    padding: 20px 0;

    &.style-error {
        color: #9d0000;
    }

    span {
        cursor: pointer;
        text-decoration: underline;
    }

    .message {
        text-align: left;
        margin-bottom: 5px;
        padding: 0 35px;

        &:nth-child(2) {
            margin-top: 15px;
        }
    }
}
