@import "../../scss/partials/_all.scss";
$switch-active: #bada55;
$switch-disabled: #bfbfbf;

.toggle-btn-checkbox {
    .switch {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 33px;
        height: 21px;
        background: $switch-disabled;
        border-radius: 15px;
        position: relative;
        margin: 0;
    }

    label:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 17px;
        height: 17px;
        background: #fff;
        border-radius: 10px;
        transition: 0.3s;
    }

    .switching + label {
        background: $gray-light !important;
    }

    input:checked + label {
        background: $switch-active;
    }

    input:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }

    label:active:after {
        width: 17px;
    }
}
