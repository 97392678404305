.swiipe-form-footer.form-footer {
    p {
        font-size: 16px !important;
        margin-bottom: 0px;
    }

    .lock {
        height: 20px;
        vertical-align: top;
        padding-bottom: 1px;
    }

    .logo {
        height: 22px;
        padding-left: 2px;
        padding-bottom: 1px;
    }
}
