#sw-services-onboarding-page {
    .main-container {
        width: 560px !important;
        background-color: white;
        margin: auto;
        margin-bottom: 80px;
        padding: 50px 70px;
        border-radius: 0px;

        &.main-container--wide {
            width: inherit !important;
        }
    }

    .block-heading {
        color: #1a496d;
        font-size: 1.45em;
    }

    .text-normal {
        color: #495057;
        font-size: 15px;

        a {
            font-weight: 600;
            color: #1176a2;
        }

        .italic {
            font-style: italic;
            color: #78838e;
        }

        &.blue {
            color: #1a496d;
            font-size: 16px;
        }
    }

    .text-thin {
        color: #495057;
        font-size: 15px;
        font-weight: 300;
    }

    .contact-us-btn {
        color: #9fa5aa;
        text-align: center;
        text-decoration: underline;

        span {
            cursor: pointer;

            &:hover {
                color: #666666;
                text-decoration-color: #9fa5aa;
            }
        }
    }

    .section-title--bigger,
    .hint-bigger {
        p {
            font-weight: normal !important;
            font-size: 1.55em !important;
        }

        .alert-f2 {
            font-size: 1.2em !important;
            margin-top: 0;
        }
    }

    .webhsop-select {
        margin: 0 40px;

        button {
            margin: 15px 0 0 0 !important;
        }
    }

    @media screen and(max-width: 705px) {
        .main-container {
            width: auto !important;
            padding: 50px 30px;
        }
    }

    @media screen and(max-width: 415px) {
        .main-container {
            width: auto !important;
            padding: 50px 15px;
        }

        .webhsop-select {
            margin: 0 10px;
        }
    }
}
