.applepay-id-step {
    background-color: white;
    margin: 20px;
    padding: 20px;
    border-radius: 5px;

    .applepay-id-form {
        display: flex;
        margin-top: 40px;
        padding: 0 15%;
        align-items: center;
        flex-direction: column;

        .applepay-id-input {
            width: 100%;
            margin-bottom: 0;
        }

        .applepay-id-text {
            font-size: 1.2em;
            color: #1a496d;
            margin-bottom: 5px;
            display: block;
        }

        .applepay-id-input-subtext {
            width: 100%;
            text-align: right;
        }

        .applepay-yesno-radiogroup {
            margin-top: 4em;

            .applepay-radio-label {
                padding-left: 5px;
            }

            &__radio-container {
                margin-left: 20px;
            }
        }

        .submit-btn {
            max-width: 280px;
            width: 50%;
            margin-top: 3em;
        }
    }

    .applepay-grey-textbox {
        text-align: center;
        background-color: #f2f2f2;
        padding: 15px 30px;
        font-size: 15px;
        font-family: "Open Sans";
        color: #1a496d;
        width: 90%;
        margin: 0 auto;
    }

    .applepay-sub-text {
        color: #797979;
        text-align: center;
        font-size: 0.95em;
        margin-top: 5px;
    }

    @media screen and (max-width: 475px) {
        .applepay-grey-textbox {
            width: 100%;
        }
    }

    @media screen and (max-width: 685px) {
        .applepay-id-form {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 475px) {
        .applepay-id-form {
            margin-top: 40px;
            padding: 0 5%;

            .submit-btn {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 380px) {
        .applepay-id-form {
            padding: 0;
        }
    }
}
