@import "../../scss/partials/_all.scss";

.infobox-editor-container {
    .edit {
        font-size: 14px;
        display: flex;
        align-items: center;
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;

        span {
            display: flex;

            img {
                margin-left: 5px;
                width: 18px;
                height: 18px;
            }
        }
    }

    &.edit-btn-bind-top {
        .border-container {
            .edit {
                height: auto;
            }
        }
    }
}

.infoboxeditor-form {
    .btn-infobox-delete {
        &--text-smaller {
            font-size: 11px !important;
        }
    }
}

.inner-info-container.isineditmode {
    .border-container.default {
        border-color: $green-menu !important;
    }
}

@media screen and (max-width: 575px) {
    .infoboxeditor-form {
        .btn-infobox-delete {
            margin-top: 15px !important;
        }
    }
}
