@import "../../scss/partials/all.scss";

.a2a-account {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    flex-wrap: wrap;

    .a2a-account__info-container {
        display: flex;
        flex-wrap: nowrap;
        font-size: 14px;
        height: 20px;
        align-items: center;

        @media screen and (max-width: 830px) {
            height: auto;
        }

        @media screen and (max-width: 600px) {
            align-items: flex-start;
            flex-direction: column;
        }

        .a2a-account__logo-currency-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 15px;

            .a2a-account__providerLogo {
                max-width: 75px;
                margin-bottom: 2px;
            }

            .a2a-account__currency {
                font-weight: 600;
            }
        }
        .a2a-account__bankDetails-container {
            display: flex;
            flex-basis: 40%;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: flex-start;
        }
    }

    .a2a-account__notification {
        width: fit-content;
        max-width: 70%;
        margin-top: 20px;
        padding: 7px;

        &.a2a-account__notification--warning {
            background-color: #fff3cd;
            color: #856404;
        }
        &.a2a-account__notification--error {
            background-color: #cc00000c;
            color: $red-error;
            padding: 10px 7px;
        }
    }
}
