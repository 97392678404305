#wrapper-with-tabs {
    .main-container-el {
        & > div {
            display: flex;
        }
    }

    .tabs-container {
        width: 100px;
        border-right: 1px solid #dadcde;
        padding: 0 0 30px 0;
        margin-right: 15px;

        .tab {
            display: block;
            text-align: center;
            padding: 10px 0;
            border-bottom: 1px solid #dadcde;

            &:hover {
                font-weight: 600;
                cursor: pointer;
                background-color: #f2f2f2;
            }

            &.active {
                font-weight: 600;
                cursor: pointer;
                background-color: #f2f2f2;
            }
        }
    }

    .element-container {
        width: 100%;
        max-width: 550px !important;
    }
}
