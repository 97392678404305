.merchant-dashboard-compare-diff {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.align-right {
        justify-content: flex-end;
        width: 43px;
    }

    .indicator {
        display: flex;
        width: 12px;
        justify-content: center;
        align-items: center;
    }
    &.color-positive .compare-arrow-up {
        border-bottom-color: #027a00;
    }
    &.color-positive .compare-arrow-down {
        border-top-color: #027a00;
    }
    &.color-positive .compare-pct {
        color: #027a00;
    }
    &.color-negative .compare-arrow-up {
        border-bottom-color: #7a0000;
    }
    &.color-negative .compare-arrow-down {
        border-top-color: #7a0000;
    }
    &.color-negative .compare-pct {
        color: #7a0000;
    }
    &.color-neutral .compare-arrow-up {
        border-bottom-color: black;
    }
    &.color-neutral .compare-arrow-down {
        border-top-color: black;
    }
    &.color-neutral .compare-pct {
        color: black;
    }
    .compare-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        border-left-width: 4px;
        border-right-width: 4px;
        border-color: transparent;
    }
    .compare-arrow-up {
        border-bottom-width: 5px;
        margin-bottom: 3px;
    }
    .compare-arrow-down {
        border-top-width: 5px;
        margin-top: 3px;
    }
    .compare-same {
        width: 7px;
        height: 2px;
        background-color: black;
    }
    .compare-pct {
        font-size: 12px;
        font-weight: 700;
    }

    &.large {
        .indicator {
            width: 14px;
        }
        .compare-arrow {
            border-left-width: 5px;
            border-right-width: 5px;
        }
        .compare-arrow-up {
            border-bottom-width: 7px;
            margin-bottom: 2px;
        }
        .compare-arrow-down {
            border-top-width: 7px;
            margin-top: 5px;
        }
        .compare-same {
            width: 9px;
            height: 2px;
        }
        .compare-pct {
            font-size: 16px;
        }
    }
}
