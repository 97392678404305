.disclaimer-toggle {
    .disclaimer-toggle__trigger {
        cursor: pointer;
        display: block;
        text-decoration: underline;
        text-align: center;
        font-weight: 300;
        font-size: 13px;
        margin-top: 5px;
    }
}
