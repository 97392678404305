@import "../../../scss/partials/_colors.scss";
.merchant-dashboard-tile-value-graph {
    display: flex;
    flex-direction: column;
    .valueRow {
        margin-top: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
    }
    .value {
        font-weight: 800;
        &--bigger {
            font-size: 32px;
        }
        &--smaller {
            font-size: 26px;
        }
    }
    .currency {
        margin-left: 10px;
        margin-top: 14px;
        font-size: 13px;
        font-weight: 800;
    }
    .divider {
        height: 31px;
        width: 1px;
        background-color: black;
        opacity: 0.4;
        margin: 3px 10px 0 10px;
    }
    .extra {
        display: flex;
        align-items: center;
    }
    .extra-text {
        font-size: 20px;
        font-weight: 800;
        &--smallest {
            font-size: 15px;
        }
    }
    .extra-subtext {
        font-size: 10px;
    }
    .merchant-dashboard-graph-container {
        margin-top: 5px;
        flex: 1;
    }
}
