#page-icon-container-comp {
    .readmore-container {
        display: flex;

        justify-content: center;
        align-items: center;
        cursor: pointer;

        .readmore-text {
            color: #797676;
            font-size: 0.85em;
            line-height: 10px;
        }

        .page-icon {
            margin: unset !important;
            display: inline !important;
            padding: 0 10px !important;
        }

        .title {
            margin-left: 10px;
        }
    }
    .description {
        font-size: 16px;
        text-align: center;
    }
}
