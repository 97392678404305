@import "../../scss/partials/all.scss";

#plugins-top,
#plugins-middle,
#plugins-bottom {
    .plugin-header-logo {
        width: 35%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    .services-title {
        text-align: center;
        color: #797676;
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .merchant-plugin-bottom-spacer {
        height: 5px !important;
    }
}

.api-documentation {
    text-align: center;
    font-size: 14px;
    a {
        color: $blue-main;
        border-bottom: 1px solid $blue-main;
        text-decoration: none;

        &:hover {
            color: $blue-link-hover;
        }
    }
}
