@import "../../styles/partials/variables";

.merchant-offer-content-container {
    padding: 40px 130px;

    @media (max-width: 1024px) {
        padding: 40px;
    }

    @media (max-width: $breakpoint-mobile) {
        padding: 30px 10px;
    }
}
