#resurs-onboarding__select-store-step {
    padding-left: 10%;
    padding-right: 10%;

    .resurs-onboarding__select-store-info {
        font-size: 16px;
        text-align: center;
        margin: 3em 0;
    }
    .resurs-choose-stores__stores-form {
        .resurs-onboarding__stores-radio-group {
            color: #495057;
            background-color: #f9f9f9;
            padding-top: 1em;
            padding-bottom: 1em;

            hr {
                margin: 0 3.5em;
            }
        }
        .resurs-onboarding__terms-link {
            font-size: 14px;
            margin: 3em 0;
        }
    }

    .resurs-onboarding__activate-button {
        margin-bottom: 3em;
    }
}
