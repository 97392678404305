.returnsell-onboarding-container {
    padding-bottom: 3em;

    .returnsell-form-head {
        text-align: center;
        margin: 20px 0 30px 0;

        .returnsell-logo {
            width: 220px;
        }
    }

    .returnsell-info {
        font-weight: 600;
        font-size: 1.25em;
        color: #1a496d;
    }

    .returnsell-form,
    .returnsell-info {
        margin-top: 10px;
        padding: 0 15%;
    }

    .desc {
        background: #f2f2f2;
        padding: 20px;
        font-size: 15px;
        margin: 0 10%;
        margin-bottom: 10px !important;

        .desc-list {
            li {
                margin: 10px 0;
            }
        }

        .read-guide {
            margin-top: 30px;

            a {
                color: #2a6fa4fa;
                text-decoration: underline;
            }
        }
    }

    @media screen and (max-width: 685px) {
        .returnsell-form {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 450px) {
        .returnsell-form {
            padding: 0 5%;
        }
    }

    @media screen and (max-width: 380px) {
        .returnsell-form {
            padding: 0;
        }
    }
}
