@import "../../styles/partials/variables";

$break-one-column: $breakpoint-tables;

.merchant-offer-order-summary {
    .merchant-offer-order-summary__title {
        font-size: 18px;
        font-weight: 700;

        @media (max-width: $break-one-column) {
            font-size: 16px;
        }
    }

    .merchant-offer-order-summary__divider {
        background-color: rgba(241, 96, 3, 0.6);
        height: 3px;
        width: 100%;
        margin: 24px 0;
    }

    .merchant-offer-order-summary__divider-light {
        background-color: rgba(241, 96, 3, 0.2);
        height: 2px;
        width: 100%;
        margin: 24px 0;

        @media (max-width: $break-one-column) {
            margin: 15px 0;
        }
    }

    .merchant-offer-order-summary__webshops {
        padding-bottom: 20px;

        .merchant-offer-order-summary__webshop {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .merchant-offer-order-summary__webshop-domain {
                font-size: 16px;
                font-weight: 700;
                display: flex;
                align-items: center;
            }
            .merchant-offer-order-summary__webshop-platform {
                img {
                    max-width: 140px;
                    margin: auto 10px;
                }
            }

            @media (max-width: $break-one-column) {
                margin-bottom: 15px;
                .merchant-offer-order-summary__webshop-domain {
                    font-size: 14px;
                }
                .merchant-offer-order-summary__webshop-platform {
                    font-size: 14px;
                    img {
                        max-width: 100px;
                        margin: auto 10px;
                    }
                }
            }
        }
    }

    .merchant-offer-order-summary__order {
        display: grid;
        grid-template-columns: 4fr 9fr 7fr;
        grid-gap: 2px;

        @media (max-width: 1200px) {
            grid-template-columns: 200px 1fr 330px;
        }

        @media (max-width: $break-one-column) {
            display: block;
        }

        .merchant-offer-order-summary__order-header-left {
            height: 70px;
            grid-column: 1 / span 2;

            background-color: #22303e;

            border-top-left-radius: 10px;
            font-size: 18px;
            font-weight: 600;
            color: white;

            padding-left: 60px;
            padding-right: 30px;
            display: flex;
            align-items: center;

            .merchant-offer-order-summary__order-header-left-title-mobile {
                display: none;
            }
            .merchant-offer-order-summary__order-header-left-title-desktop {
                display: inline;
            }

            @media (max-width: $break-one-column) {
                padding: 11px 17px;
                font-size: 14px;
                border-top-right-radius: 10px;
                height: inherit;

                .merchant-offer-order-summary__order-header-left-title-mobile {
                    display: inline;
                }
                .merchant-offer-order-summary__order-header-left-title-desktop {
                    display: none;
                }
            }

            &.merchant-offer-order-summary__order-header-left--base {
                background-color: #d6e8fa;
                margin-top: 7px;
                color: #22303e;
                margin-bottom: -2px;

                @media (max-width: $break-one-column) {
                    padding: 8px 17px;
                    font-size: 14px;
                    border-top-right-radius: 10px;
                    height: inherit;
                    margin-top: 12px;
                }
            }
        }
        .merchant-offer-order-summary__order-footer-base-left {
            grid-column: 1 / span 2;
            background-color: #d6e8fa;
            height: 14px;
            border-bottom-left-radius: 10px;
            margin-bottom: 4px;
            margin-top: -2px;

            @media (max-width: $break-one-column) {
                border-bottom-right-radius: 10px;
            }
        }

        .merchant-offer-order-summary__order-header-right {
            height: 70px;

            background-color: #22303e;
            border-top-right-radius: 10px;
            font-size: 18px;
            font-weight: 600;
            color: white;

            display: flex;
            align-items: center;
            padding-left: 50px;
            padding-right: 45px;

            @media (max-width: $break-one-column) {
                display: none;
            }

            &.merchant-offer-order-summary__order-header-right--base {
                background-color: #d6e8fa;
                margin-top: 7px;
                color: #22303e;
                margin-bottom: -2px;
            }
        }
        .merchant-offer-order-summary__order-footer-base-right {
            background-color: #d6e8fa;
            height: 14px;
            border-bottom-right-radius: 10px;
            margin-bottom: 4px;
            margin-top: -2px;

            @media (max-width: $break-one-column) {
                display: none;
            }
        }

        .merchant-offer-order-summary__order-item-left {
            color: #22303e;
            background-color: #eaf3fc;
            padding: 30px 10px 30px 60px;
            margin-right: -2px;

            .merchant-offer-order-summary__order-item-title {
                margin-bottom: 5px;
                font-size: 20px;
                font-weight: 600;
            }
            .merchant-offer-order-summary__order-item-image {
                img {
                    max-width: 120px;
                }
            }

            @media (max-width: $break-one-column) {
                padding: 20px 17px 10px 17px;
                background-color: #eaf3fc;
                border-radius: 3px;
                overflow: hidden;
                margin-right: 0;
                margin-top: 8px;

                &.merchant-offer-order-summary__order-item-left--first {
                    margin-top: 0;
                }

                &.merchant-offer-order-summary__order-item-left--empty {
                    padding: 0;
                }

                .merchant-offer-order-summary__order-item-title {
                    font-size: 16px;
                    font-weight: 700;
                    padding-bottom: 10px;
                    margin-bottom: 0;
                    float: left;
                }

                .merchant-offer-order-summary__order-item-image {
                    float: right;
                }
            }
        }
        .merchant-offer-order-summary__order-item-center {
            color: #22303e;
            background-color: #eaf3fc;
            font-size: 16px;
            font-weight: 400;
            padding: 30px 30px 30px 0;

            .merchant-offer-order-summary__order-item-intro {
                font-weight: 600;
                margin-top: 20px;
                &:first-child {
                    margin-top: 0;
                }
            }
            .merchant-offer-order-summary__order-item-statements {
                .merchant-offer-order-summary__order-item-statement {
                    display: flex;
                    margin-top: 20px;

                    &:first-child {
                        margin-top: 0;
                        &.merchant-offer-order-summary__order-item-statement--has-title {
                            margin-top: 20px;
                        }
                    }

                    .merchant-offer-order-summary__order-item-statement-icon {
                        flex: 0 0 20px;
                        width: 20px;
                        height: 20px;
                        margin-top: 2px;
                    }
                    .merchant-offer-order-summary__order-item-statement-text {
                        flex: 1 1 0;
                        margin-left: 12px;
                    }
                }
            }
            @media (max-width: $break-one-column) {
                padding: 5px 17px 0 17px;
                margin: 0;
            }
        }

        .merchant-offer-order-summary__order-item-right {
            color: #22303e;
            background-color: #eaf3fc;
            font-size: 16px;
            padding-left: 30px;
            padding-right: 45px;
            padding-top: 30px;
            padding-bottom: 30px;

            .merchant-offer-order-summary__order-item-mobile-divider {
                display: none;
                @media (max-width: $break-one-column) {
                    display: block;
                    height: 2px;
                    width: 100%;
                    margin: 15px 0;
                    background-color: white;
                }
            }

            .merchant-offer-order-summary__order-item-prices {
                .merchant-offer-order-summary__order-item-price-group {
                    margin-top: 20px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
                .merchant-offer-order-summary__order-item-price {
                    display: flex;
                    margin-bottom: 5px;
                    .merchant-offer-order-summary__order-item-price-name {
                        flex: 1 1 0;
                        padding-right: 5px;

                        &.merchant-offer-order-summary__order-item-price-name-mobile {
                            display: none;
                        }
                        &.merchant-offer-order-summary__order-item-price-name-desktop {
                            display: flex;
                        }
                        @media (max-width: $break-one-column) {
                            &.merchant-offer-order-summary__order-item-price-name-mobile {
                                display: block;
                            }
                            &.merchant-offer-order-summary__order-item-price-name-desktop {
                                display: none;
                            }
                        }

                        .merchant-offer-order-summary__order-item-price-name-title {
                            font-size: 16px;
                            color: #22303e;
                        }
                    }
                    .merchant-offer-order-summary__order-item-price-amount {
                        flex: 0 0 auto;
                        text-align: right;
                    }
                }
            }
            @media (max-width: $break-one-column) {
                padding: 5px 17px 20px 17px;
                margin: 0;
            }
        }

        .merchant-offer-order-summary__order-footer-left {
            grid-column: 1 / span 2;
        }
        .merchant-offer-order-summary__order-footer-right {
            background-color: #22303e;

            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            font-size: 18px;
            font-weight: 600;
            color: white;

            padding: 30px 45px 30px 30px;

            .merchant-offer-order-summary__order-total-row {
                font-size: 18px;
                font-weight: 700;
                display: flex;

                .merchant-offer-order-summary__order-total-title {
                    flex: 1 1 0;
                }
                .merchant-offer-order-summary__order-total-value {
                    flex: 0 0 auto;
                    text-align: right;
                }
            }
            .merchant-offer-order-summary__order-total-extra-desc {
                font-size: 16px;
                font-weight: 600;
                text-align: right;
                margin-top: 5px;
            }

            @media (max-width: $break-one-column) {
                margin-top: 10px;
            }
        }
    }
}
