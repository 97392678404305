.merchant-contact-info-box {
    .merchant-contact-info-box__preview-row {
        margin-bottom: 0.6em;

        &--comment {
            background: #f2f2f2;
            padding: 10px;
        }
    }
}
