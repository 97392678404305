@import "../../scss/partials/all";

.delete-account-container {
    padding: 40px 50px !important;

    a {
        text-decoration: underline;
        color: #23638d;

        &:hover {
            text-decoration: none;
        }
    }

    .heading {
        font-weight: 600;
        font-size: 1.2em;
    }

    .action-button {
        width: 180px;
        text-transform: inherit !important;
        height: 40px;
    }

    .buttons-container {
        display: flex;
        justify-content: space-around;

        button {
            width: 200px;
        }
    }

    .request-sent {
        display: flex;
        font-size: 1.4em;
        align-items: center;

        img {
            margin-right: 5px;
            width: 35px;
            height: 35px;
        }
    }

    .delete-account-is-expedite__container-outer {
        background-color: $gray-background;
        padding: 20px 20px 10px 20px;
        margin-bottom: 20px;
    }

    .delete-account-is-expedite__container {
        display: flex;
        padding-top: 5px;
        .delete-account-is-expedite__title {
            padding-left: 10px;
            font-size: 0.8em;
        }
    }
}
