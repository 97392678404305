@import "../../../scss/partials/all.scss";

#signup {
    .logo-img {
        width: 100%;
        height: auto;
    }

    #signup-btn {
        padding: 0px;
        border: none;
        opacity: 1;
        cursor: pointer;
        background-color: unset !important;
        height: auto;
        /*width: 100%;
        background-color: $blue-main;*/
    }

    #back-btn {
        width: 30%;
    }

    ul {
        padding-left: 15px;
    }

    .form-footer {
        margin-top: 1.5rem !important;
    }
}
