@import "../../../scss/partials/_colors.scss";
.merchant-dashboard-tile-circle-with-list {
    display: flex;
    flex-direction: column;

    .data-container {
        display: flex;
        flex: 1;
        flex-wrap: wrap;

        .merchant-dashboard-circle-container {
            flex: 2;
        }

        .data-list {
            flex: 3;
            display: flex;
            flex-direction: column;

            .data-item {
                display: flex;
                height: 32px;
                align-items: center;
                &.odd {
                    background-color: rgba(11, 97, 144, 0.08);
                }

                .data-color {
                    width: 12px;
                    height: 12px;
                    border-radius: 12px;
                    margin-left: 15px;
                    margin-right: 10px;

                    &.selected {
                        transform: scale(1.4);
                    }
                }

                .data-title {
                    flex: 1;
                    font-size: 16px;
                }

                .data-value {
                    font-size: 16px;
                    font-weight: 700;
                    color: $dark-blue !important;
                    margin-right: 22px;
                }

                .data-compare {
                    width: 60px;
                    display: flex;
                    justify-content: flex-start;
                }
            }

            &.lots-of-rows {
                .data-item {
                    height: 28px;
                }
            }
        }
        .other-content {
            flex: 1 1 100%;
            font-size: 16px;
            padding-top: 20px;
        }
    }

    &.rows2 {
        .data-container {
            flex-direction: column;
        }
        .merchant-dashboard-circle-container {
            flex: 1;
        }
        .data-list {
            flex: 0 1 auto;
        }
        .other-content {
            flex: 1 1 auto;
        }
    }
}
