.report-fraud-modal {
    .order-info {
        font-size: 15px;
        background: #f2f2f27f;
        padding: 20px;
        color: #0e3251;
        border-radius: 5px;

        div {
            margin-bottom: 2px;
            display: flex;
            justify-content: space-between;

            &:nth-child(1) {
                font-size: 17px;
                margin-bottom: 12px;
                font-weight: 600;
            }
        }
    }

    .input-group {
        textarea {
            font-weight: 500;
        }
    }

    .modal-title {
        color: #910012;
        font-size: 17px;
        font-weight: 400;
        padding: 0.4rem;
    }
}
