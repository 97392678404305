.analytics-onboarding-page
{
    .analytics-onboarding-page__wrapper-box {
        background: white;
        border-radius: 5px;
        padding: 20px 25px;
        margin-top: 10px;
    }

    .analytics-onboarding-page__header {
        text-align: center;
        font-size: 1.4em;
        font-weight: 300;

        img {
            width: 140px;
        }

        img,
        > p {
            margin-top: 1em;
        }
    }

    .analytics-onboarding-page__step {
        display: flex;
        color: #1A496D;

        .analytics-onboarding-page__step-number {
            font-weight: 600;
            font-size: 1.4em;
            min-width: 150px;
            text-align: center;
        }

        .analytics-onboarding-page__step-description {
            font-size: 16px;
        }

        .analytics-onboarding-page__step-text-btn {
            font-size: 13px;
        }
    }

    .analytics-onboarding-page__input {
        max-width: 270px;
    }

    .analytics-onboarding-page__copy-row {
        display: flex;
        align-items: baseline;
        .analytics-onboarding-page__copy-input {
            min-width: 270px;
            margin-right: 20px;
        }
        .analytics-onboarding-page__copy-button {
            max-width: 100px;
            height: 38px;
        }
    }

    .analytics-onboarding-page__submit-btn {
        text-align: center;
        margin-bottom: 1em;

        button {
            max-width: 245px;
        }
    }
}