.dankort-onboarding-container {
    .dankort-form-head {
        text-align: center;
        margin: 20px 0 30px 0;

        .dankort {
            width: 45px;
            margin-right: 5px;
            margin-top: -25px;
        }

        .swiipe-payments {
            width: 170px;
        }
    }

    .dankort-text-1 {
        text-align: center;
        background-color: #f2f2f2;
        padding: 15px 25px 15px 25px;
        font-size: 15px;
        font-family: "Open Sans";
        color: #1a496d;
        width: 90%;
        margin: 0 auto;
    }

    .dankort-text-2 {
        color: #797979;
        text-align: center;
        font-size: 1.1em;
        margin-top: 8px;
    }

    .dankort-form {
        margin-top: 60px;
        padding: 0 19%;

        .dankort-id-text {
            font-size: 1.2em;
            color: #1a496d;
            margin-bottom: 5px;
            display: block;
        }

        .submit-btn {
            max-width: 280px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 685px) {
        .dankort-form {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 450px) {
        .dankort-form {
            margin-top: 40px;
            padding: 0 5%;

            .submit-btn {
                max-width: 240px;
            }
        }

        .dankort-text-1 {
            width: 100%;
        }
    }

    @media screen and (max-width: 380px) {
        .dankort-form {
            padding: 0;

            .submit-btn {
                max-width: 200px;
            }
        }
    }

    .dankort-back-link {
        margin-bottom: 25px;
        cursor: pointer;
        text-align: center;

        a {
            color: #23638e;
            text-decoration: none;
        }
    }
}
