.invoices-page {
    .invoices-spinner-container {
        min-height: 100px;
    }
    .invoices-empty {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .invoices-table {
        width: 100%;
        font-size: 0.9em;

        .actions-column-header {
            max-width: 160px;
            text-align: right;
        }

        th {
            height: 50px;
            font-size: 1em;
        }

        tr {
            height: 55px;
            border-top: 1px solid #dfdfdf;

            &:nth-child(1) {
                border: none;

                &:hover {
                    background-color: unset;
                    cursor: unset;
                }
            }

            &:hover {
                background-color: #f5f5f5;
            }

            td:hover {
                cursor: pointer;
            }

            td:last-child {
                cursor: unset;
            }
        }

        th,
        td {
            min-width: 60px;
        }

        tr:nth-child(1) {
            td {
                white-space: nowrap;
            }
        }

        td {
            font-weight: 300;

            .actions-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: 10px;

                button {
                    padding: 0 15px;
                    background: transparent !important;
                    font-size: 14px;
                    height: 29px;
                    white-space: nowrap;

                    color: #23638e;
                    border-color: #d7d7d7;

                    &:hover {
                        background-color: #23638e2b !important;
                    }
                }
            }

            .status-text {
                &.default {
                    color: darkgray;
                }
                &.green {
                    color: green;
                }
                &.red {
                    color: red;
                }
            }
        }
    }

    .divider {
        max-width: 60%;
        margin: 3px auto;
    }

    .hover-effect:hover {
        border-radius: 7px;
        background-color: #f5f5f5;
    }

    @media screen and (max-width: 960px) {
        .invoices-table {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            td,
            th {
                min-width: 100px;
            }
        }
    }

    @media screen and (max-width: 340px) {
        padding: 15px 5px !important;
    }
}
