@import "../../styles/partials/variables";

.merchant-offer-header {
    background-image: url("../../images/merchantOffer/merchant-offer-banner.png");
    background-size: cover;
    background-position: 50% 50%;

    position: relative;
    .merchant-offer-header-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #22303e;
        opacity: 0.9;
    }

    .merchant-offer-header-content {
        position: relative;
        padding: 70px 140px;
    }

    .merchant-offer-header-title {
        color: white;
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 60px;

        @media (max-width: $breakpoint-mobile) {
            display: none;
        }
    }

    .merchant-offer-header-heading {
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 5px;
    }

    .merchant-offer-header-owner {
        margin-top: 55px;
    }

    .merchant-offer-header-main {
        display: flex;
        font-size: 16px;

        .merchant-offer-header-main-left {
            flex: 1 1 0;
            color: white;
        }
        .merchant-offer-header-main-right {
            flex: 1 1 0;
            text-align: right;
            color: white;
        }
    }

    @media (max-width: $breakpoint-large) {
        .merchant-offer-header-content {
            padding: 50px 50px;
        }
    }

    @media (max-width: $breakpoint-mobile) {
        .merchant-offer-header-content {
            padding: 30px 30px;
        }

        .merchant-offer-header-main {
            display: block;

            .merchant-offer-header-main-right {
                text-align: left !important;
                margin-top: 30px;
            }
            .merchant-offer-header-owner {
                margin-top: 30px;
            }
        }
    }
}
