.banner-top-red {
    font-size: 0.9em;
    padding: 20px;
    background-color: #e4cdcd;
    color: #990000;
    text-align: center;

    span {
        text-decoration: underline;
        cursor: pointer;
    }

    .merchant-will-be-deleted-banner__revival-text {
        display: inline-block;
        padding-right: 5px;
    }

    .merchant-will-be-deleted-banner__revival-action {
        display: inline-block;
    }
}
