#download-onboarding-data-page {
    .application-box {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        font-size: 1.2em;

        .name {
            width: 40%;
        }

        .date {
            width: 30%;
        }

        .swiipe-spinner-container {
            max-width: 120px;
            width: 30%;
            margin-left: auto;
        }
    }
}
