@import "../../styles/partials/variables";

.merchant-offer-footer {
    font-size: 18px;
    color: #22303e;
    margin-bottom: 60px;

    .merchant-offer-footer__title {
        font-weight: 700;
    }

    .merchant-offer-footer__divider {
        background-color: rgba(241, 96, 3, 0.6);
        height: 3px;
        width: 100%;
        margin: 24px 0;
    }
}
