.modal-bulk-actions {
    .success-container {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 40px;
            margin-left: 10px;
        }
    }

    .empty-spinner-container {
        margin: 40px 0;

        .spinner {
            bottom: 35px;
        }
    }
}
