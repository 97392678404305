.onboarding-anyday-connect {
    .onboarding-anyday-connect__intro-box {
        text-align: center;
        background-color: #f2f2f2;
        padding: 15px 25px 15px 25px;
        font-size: 15px;
        font-family: "Open Sans";
        color: #1a496d;
    }
    .onboarding-anyday-connect__intro-link {
        color: #797979;
        text-align: center;
        font-size: 1.1em;
        margin-top: 8px;
        margin-bottom: 50px;
    }

    .onboarding-anyday-connect__submit-btn {
        max-width: 240px;
        margin: 40px auto 10px auto;
    }

    .onboarding-anyday-connect__back-link {
        padding-top: 30px;
        text-align: center;
        cursor: pointer;
    }

    .onboarding-anyday-connect__form {
        margin-top: 60px;
        padding: 0 10%;

        .submit-btn {
            max-width: 280px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 450px) {
        .onboarding-anyday-connect__intro-box {
            width: 100%;
        }
        .onboarding-anyday-connect__form {
            padding: 0;
        }
    }
}
