.swiipe-accept-terms {
    color: #7f7f7f;

    &.in-plugin-desc {
        display: inline;
    }

    a {
        text-decoration: none !important;
        font-weight: normal !important;
    }
}
