@import "../../scss/partials/_all.scss";

.external-link-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    padding: 0 2em;
    border-radius: 5px;

    &.swiipe-btn-dark {
        color: white;
    }

    &.external-link-button--text-only {
        a {
            color: $blue-main !important;
            display: inline-block;
            text-decoration: underline dotted !important;
        }

        a:hover {
            text-decoration: none;
            color: $blue-link-hover !important;
            border-color: $blue-link-hover;
        }
    }

    a,
    a:visited {
        color: inherit;
    }

    a:hover {
        text-decoration: none;
    }
}
