.payment-method-container {
    .credit-card {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &.form-fields {
            flex-wrap: wrap;

            .info {
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .card-date {
                margin: initial;
            }
        }

        .card-logo {
            max-width: 40px;
        }

        .card-number {
            margin-left: 25px;
        }

        .card-date {
            margin: 0 0 0 auto;
        }

        @media screen and (max-width: 450px) {
            .card-number {
                margin-left: 5px;
                font-size: 0.9em;
                margin: 0 auto 0 5px;
            }

            .card-date {
                font-size: 0.9em;
                margin: 0 auto 0 0;
                flex-basis: 100%;
            }
        }
    }

    .account2account {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        &.form-fields {
            flex-wrap: wrap;

            .info {
                width: 100%;
                display: flex;
                flex-direction: row;
            }
        }

        .bank-logo {
            max-width: 8em;
        }

        @media screen and (max-width: 450px) {
            .account-name {
                margin-left: 5px;
                font-size: 0.9em;
                margin: 0 auto 0 5px;
            }
        }
    }
}
