.dropdown-with-button {
    width: 240px;
    display: flex;
    flex-direction: row;

    select {
        height: 32px;
        width: 65%;
        font-size: 1.1em;
        padding: 0px 6px !important;
        margin-right: 8px;
        color: #7f7f7f;
    }

    .button {
        height: 32px;
        width: 35%;
        font-weight: 400;

        background-color: #d7d7d7 !important;
        color: #696969;
    }
}
