.title-with-absolute-hint {
    display: flex;
    cursor: pointer;

    .hint-icon {
        position: relative;

        img {
            width: 16px;
        }

        .hint-icon-type {
            margin-top: 4px;
        }
    }

    &.no-hint {
        cursor: inherit;
    }

    &.title-light .hint-title {
        font-weight: 300;
    }

    .hint-title {
        margin-left: 5px;
        font-size: 14px;

        &.hint-title--no-icon {
            margin-left: 0;
        }
    }

    .hint-arrow {
        position: absolute;
        top: 12px;
        left: -23px;
        width: 10px;
        height: 10px;
        z-index: 1000;
        background-color: #edf0f5;
        transform: translate(50%, -50%) rotate(45deg);

        -webkit-box-shadow: 4px -3px 4px 0px #223c5033;
        -moz-box-shadow: 4px -3px 4px 0px #223c5033;
        box-shadow: 4px -3px 4px 0px #223c5033;

        &.white {
            background-color: white;
            border-left: 1px solid #ced4da;
            border-top: 1px solid #ced4da;
            z-index: 2;

            box-shadow: none;

            transform: translate(50%, -50%) rotate(135deg);
        }

        &.show-on-bottom {
            top: 25px;
            left: -2px;
            box-shadow: none;
            transform: translate(50%, -50%) rotate(45deg);
        }

        &.show-on-top {
            top: inherit;
            bottom: 14px;
            left: -2px;
            transform: translate(50%, -50%) rotate(135deg);

            &.white {
                transform: translate(50%, -50%) rotate(-135deg);
            }
        }
    }

    &.large-hint .hint {
        font-size: 16px;

        &.white {
            font-size: 16px;
        }
    }

    .hint {
        background-color: #edf0f5;
        max-width: 200px;
        position: absolute;
        color: #1a496d;
        font-size: 14px;
        border-radius: 5px;
        padding: 10px 15px;
        z-index: 999;
        text-align: left;

        left: -213px;
        top: -5px;

        -webkit-box-shadow: 4px 4px 3px 0px #223c5033;
        -moz-box-shadow: 4px 4px 3px 0px #223c5033;
        box-shadow: 4px 4px 3px 0px #223c5033;

        &.white {
            background-color: white;
            border: 1px solid #ced4da;
            z-index: 1;
            color: #797676;
            font-size: 12px;
        }

        &.show-on-bottom {
            width: 250px;
            max-width: 250px;
            top: 25px;

            left: -15px;
        }

        &.show-on-top {
            width: 250px;
            max-width: 250px;
            top: -6px;
            transform: translateY(-100%);

            left: -15px;
        }
    }

    &.hint-icon-right {
        flex-direction: row-reverse;
        justify-content: flex-end;

        &.no-title {
            .hint-title {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .hint-title {
            margin-left: 0;
            margin-right: 10px;
        }

        .hint-arrow {
            &.show-on-bottom {
                right: 8px;
                top: 25px;
                left: inherit;
            }
        }

        .hint {
            &.show-on-bottom {
                min-width: 250px;
                width: 250px;
                left: -200px;
                top: 25px;
            }
        }

        .hint-arrow {
            &.show-on-top {
                right: 8px;
                top: -5px;
                left: inherit;
            }
        }

        .hint {
            &.show-on-top {
                min-width: 250px;
                width: 250px;
                left: -200px;
                top: -5px;
            }
        }
    }
}
