.plussell-onboarding-container {
    padding-bottom: 3em;

    .plussell-form-head {
        text-align: center;
        margin: 20px 0 30px 0;

        .plussell-logo {
            width: 190px;
        }
    }

    .hint-disclaimer {
        margin: 0 10%;

        .list-container {
            padding: 0 7%;
            .heading {
                font-size: 15px;
            }
        }
    }

    .hint-top {
        background: #f2f2f2;
        padding: 20px;
        font-size: 15px;
        margin: 0 10%;
        margin-bottom: 10px !important;

        .head {
            display: block;
            text-align: center;
        }
    }

    .plussell-info {
        font-weight: 600;
        font-size: 1.25em;
        color: #1a496d;
    }

    .plussell-form {
        margin-top: 10px;
        padding: 0 15%;

        textarea {
            resize: none;
            font-weight: 300;
            background-color: #f2f2f27f;
            border: unset;
        }

        .submit-btn {
            //max-width: 280px;
            margin: 0 auto;
        }
    }

    .checkbox-text {
        color: #333333;
        font-size: 15px;
    }

    @media screen and (max-width: 685px) {
        .plussell-form {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 450px) {
        .hint-disclaimer,
        .hint-top {
            margin: 0;
        }

        .plussell-form {
            padding: 0 5%;
        }
    }

    @media screen and (max-width: 380px) {
        .plussell-form {
            padding: 0;
        }
    }
}
