@import "../../scss/partials/all";

#cancel-service-container {
    .main-container {
        width: 560px !important;
        background-color: white;
        margin: auto;
        margin-bottom: 80px;
        padding: 50px 70px 15px 70px;
        border-radius: 0px;

        & > p {
            text-align: center;
            font-size: 1.4em;
        }

        .cancel-text {
            background-color: #f2f2f280;
            padding: 15px;
            font-size: 1.1em;
            text-align: center;
        }
    }

    .cancelled-box {
        p {
            font-size: 1.4em;
            color: #797979;
            text-align: center;
            font-weight: 300;

            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .no-logo-container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }

    @media screen and (max-width: 720px) {
        .main-container {
            width: auto !important;
        }
    }

    @media screen and (max-width: 420px) {
        .main-container {
            width: auto !important;
            padding: 35px 15px;
        }
    }
}
