@import "../../../scss/partials/_colors.scss";
.merchant-dashboard-tile-simple-values {
    display: flex;
    flex-direction: column;

    .simple-values {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .simple-value {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            &.single-value {
                align-items: flex-start;
                margin-top: 10px;
                margin-left: 10px;
            }

            .value-container {
                display: flex;
                position: relative;
            }

            .data-compare {
                display: flex;
                align-items: center;
                margin-top: 2px;
                margin-left: 4px;
                
                &.absolute-transformed {
                    position: absolute;
                    right: -7px;
                    transform: translateX(100%);
                    height: 100%;
                }
            }

            .value {
                font-size: 26px;
                font-weight: 800;
            }

            .currency {
                margin-left: 10px;
                margin-top: 16px;
                font-size: 13px;
                font-weight: 800;
            }

            .subtitle {
                font-size: 12px;
                text-align: center;
            }
        }

        .divider {
            height: 100%;
            min-height: 55px;
            width: 1px;
            background-color: black;
            opacity: 0.4;
            margin: 0 10px;
        }
    }

    @media screen and (max-width: 785px) {
        .simple-values {
            flex-direction: column;
            
            .divider {
                width: 100%;
                min-height: unset;
                border-bottom: 1px solid #bdbdbd;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }

    }
}
