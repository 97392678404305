.a2a-infobox-container {
    button {
        &[type="submit"] {
            font-size: 10px !important;
        }

        &.btn-infobox-delete {
            font-size: 13px !important;
        }
    }

    .a2a-account__selected-header {
        background: #e8eef1;
        color: #0e3251;
        padding: 2px 14px;
        font-weight: lighter;
        font-size: 15px;
        margin: 1rem 0px;
    }

    .inner-info-container {
        .border-container {
            font-size: 14px;
        }

        &.isineditmode {
            .border-container {
                &.neutral {
                    border-color: #e0e668 !important;
                }
            }
        }
    }

    .info-container {
        span#a2a-infobox__no_accounts_text {
            font-size: 14px;
        }
    }
}
