.modal-email {
    .content-container {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 40px;
            margin-left: 10px;
        }
    }

    .modal-dialog {
        max-width: calc(100% - 40px) !important;
    }
}
