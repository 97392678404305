@import "../../scss/partials/all.scss";

.textbox-container {
    display: flex;
    margin-top: 1rem;
    background: #f2f2f2;
    text-align: justify;
    padding: 20px 20px;

    span.textbox-container__content {
        font-size: 14px;
    }

    &.textbox-container--warning {
        background: #fff3cd;

        span.textbox-container__content {
            color: #856404;
        }
    }

    &.textbox-container--error {
        span.textbox-container__content {
            color: #d50000;
        }
    }

    &.textbox-container--info {
        background: #f3f6f8;
    }

    &.textbox-container--center-text {
        text-align: center;
    }
}
