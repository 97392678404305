.form-head {
    &.form-head-logos {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin: 0 !important;
        }
    }

    .additional-logos {
        display: flex;

        &.additional-logos__column {
            display: flex;
            flex-flow: column;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    @media screen and (max-width: 400px) {
        &.form-head-logos {
            margin-bottom: 2rem;
            flex-direction: column;

            img:nth-child(2) {
                margin-top: 10px !important;
            }
        }
    }
}
