.standard-paragraph {
    color: #555555;
    font-size: 16px;
    font-weight: 300;

    &--subtitle {
        color: #23638e;

        font-weight: 500;
    }
}
