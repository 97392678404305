@import "../../scss/partials/all";

#add-credit-card-page {
    .back-button {
        justify-content: flex-start;
    }
    .main-container {
        padding: 25px 95px;
    }

    .block-heading {
        margin: 0;
        font-weight: 600;
        font-size: 1.2em;
        color: #1a496d;
        position: relative;

        .text-card-logos {
            display: flex;
            position: absolute;
            top: -5px;
            right: 0;

            .icon-visa {
                background-position-y: 2px;
                background-image: url("../../images/swCardLogos/visa-card-logo.png");
            }

            .icon-mastercard {
                background-image: url("../../images/swCardLogos/mastercard-card-logo.png");
            }
        }
    }

    .btn-confirm {
        max-width: 150px;
        display: block;
        margin: 0 0 0 auto;
    }

    .btn-close {
        max-width: 150px;
        display: block;
        margin: 0 auto;
    }

    .text-normal {
        color: #7f7f7f;
    }

    #hosted-fields-wrapper {
        div:nth-child(1) {
            display: block;
            width: 100%;
        }

        div:nth-child(2) {
            display: inline-block;
            width: 49%;
        }

        div:nth-child(3) {
            width: 49%;
            float: right;
        }

        .control {
            position: fixed;
            width: 100%;
        }

        iframe {
            border: none;
            width: 100% !important;

            input {
                width: 10%;
            }
        }
    }

    @media screen and (max-width: $breakpoint-tables) {
        .main-container {
            padding: 25px 40px;
        }
    }

    @media screen and (max-width: 400px) {
        .main-container {
            padding: 25px 0px;
        }
    }
}
