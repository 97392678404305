.applepay-details {
    display: flex;
    padding: 0 15%;
    align-items: center;
    flex-direction: column;
    background: white;
    padding-top: 35px;

    .applepay-id-input {
        width: 100%;
    }
}
