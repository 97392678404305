@import "../../../scss/partials/all.scss";

.form-head {
    .header-message {
        font-size: 18px !important;
        font-weight: lighter !important;
        color: $gray-font !important;
    }

    &.weight-medium {
        font-weight: 500 !important;
    }

    .sandbox-label {
        color: white;
        background: #088488;
        font-size: 1.1em;
        font-weight: 600;
        padding: 5px 20px;
        border-radius: 5px;
        display: table;
        margin: 0 auto;
    }
}
