.home-header {
    margin: 12px 0;
    h1 {
        font-size: 3rem;
        animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
        @keyframes tracking-in-expand-fwd {
            0% {
                letter-spacing: -0.5em;
                transform: translateZ(-700px);
                opacity: 0;
            }
            40% {
                opacity: 0.6;
            }
            100% {
                transform: translateZ(0);
                opacity: 1;
            }
        }
    }
}

#home {
    margin-top: 100px;
    text-align: center;
}

.home-body {
    margin: 12px 0;
    p {
        animation: fadein 1s;
        @keyframes fadein {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
}
