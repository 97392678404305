.text-btn {
    text-align: center;
    span {
        cursor: pointer;
    }

    &--color {
        &-blue {
            color: #23638e;
        }

        &-grey {
            color: #797976;
        }

        &-red {
            color: #cc0000;
        }

        &-dark-blue {
            color: #0e3251;
        }
    }

    &--text-decoration {
        &-underline {
            span {
                text-decoration: underline;
            }
        }

        &-underline-dotted {
            span {
                text-decoration: underline dotted;
            }
        }
    }

    &--font-size {
        &-big {
            font-size: 16px;
        }

        &-smaller {
            font-size: 12px;
        }

        &-smallest {
            font-size: 11px;
        }
    }

    &--font-style {
        &-italic {
            font-style: italic;
        }
    }

    &--font-weight {
        &-600 {
            font-weight: 600;
        }
    }

    &--display {
        &-inline-block {
            display: inline-block;

            .text-btn__spinner-container {
                width: auto;
                display: inline-block;
            }
        }
    }

    &--width {
        &-fit-content {
            width: fit-content;
        }
    }
}
