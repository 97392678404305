.close-btn-component {
    cursor: pointer;
    width: 32px;
    height: 32px;
    opacity: 0.3;

    transition: 0.1s ease;

    &:hover {
        transform: scale(1.1) !important;
        opacity: 1;
    }

    &:before,
    &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 1px;
        background-color: #333;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}
