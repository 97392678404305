.sw-pagination-section {
    line-height: 30px;
    color: #797676;

    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;

    &.align-right {
        span:nth-child(1) {
            margin-left: auto;
        }
    }

    div {
        color: white;
        font-size: 1.4em;
        font-weight: 600;
        background: #d7d7d7;
        padding: 0 10px;
        border-radius: 4px;
        height: 30px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.active {
            color: #797676;
            cursor: pointer;
        }
    }

    .per-page {
        white-space: nowrap;
        margin-right: 10px;
    }

    .page-number {
        white-space: nowrap;
        padding: 0 15px;
    }
}
