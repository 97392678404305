@import "../../../styles/partials/variables";

.phone-input-component {
    position: relative;

    .phone-input-floating-label {
        position: absolute;
        pointer-events: none;
        left: 85px;
        top: 9px;
        transition: 0.2s ease all;
        font-size: 16px;
        color: #797676;

        &.reduced {
            top: -2px;
            font-size: 11px;
            opacity: 1;
        }
    }

    .intl-tel-input {
        width: 100%;

        input {
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: $input-border-radius;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem #007bff40;
            }
        }

        .country-list {
            width: 100%;
            overflow-x: hidden;
            min-width: 260px;
            padding: 0 !important;
        }

        .selected-flag {
            display: flex !important;
            border-radius: $input-border-radius 0 0 $input-border-radius;

            .selected-dial-code {
                padding-left: 5px;
                font-size: 13px;
                font-weight: 600;
            }
        }
    }

    .hidden-input {
        //d-none is not an option
        //should be "visible" for react-forms
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
        padding: 0;
    }
}
