@import "../../scss//partials/variables";

.merchant-offer-terms {
    font-size: 17px;

    .merchant-offer-terms__title {
        margin-bottom: 20px;
    }

    .merchant-offer-terms__desc {
        margin-bottom: 50px;
    }

    .merchant-offer-terms__checkboxes-container {
        display: inline-block;
        margin-right: 40px;

        @media (max-width: $breakpoint-mobile) {
            margin-right: 0;
        }
    }

    .merchant-offer-terms__terms-link {
        font-size: 16px;
        padding-top: 1px;
        .merchant-offer-terms__terms-link-inner {
            color: #22303e;
            text-decoration: underline !important;
        }
    }

    .merchant-offer-terms__terms-no-checkbox-text {
        margin-bottom: 15px;
        > * {
            display: inline-block;
            padding-right: 4px;
        }
    }

    .merchant-offer-terms__button-container {
        display: flex;
        justify-content: flex-end;

        .merchant-offer-terms__button {
            padding-left: 70px;
            padding-right: 70px;
            height: 54px;
            font-size: 16px;
        }
        @media (max-width: $breakpoint-mobile) {
            .merchant-offer-terms__button {
                padding-left: 20px;
                padding-right: 20px;
            }
            display: block;
        }
    }

    .merchant-offer-terms__button-warning {
        font-size: 16px;
        background-color: rgba(255, 243, 205, 1);
        padding: 18px;
        float: right;
        margin-top: 30px;
    }

    .merchant-offer-terms__success-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }
}
