.merchant-orders-page {
    .actions-column-header {
        max-width: 160px;
    }

    .merchant-orders-page__header-cell,
    .merchant-orders-page__data-cell {
        &.order__platform-order-id {
            padding-left: 3px;
            &.order__platform-order-id--long {
                padding-right: 15px;
            }
        }

        &.payment__created-date {
            padding-right: 15px;
        }

        &.payment__currency {
            text-align: center;
        }

        &.payment__amount {
            min-width: 75px;
            text-align: center;
        }

        &.payment-type__name {
            width: 155px;
        }

        &.payment__icons {
            .payment-type__icon {
                display: inline-block;
            }
            img {
                width: 25px;
                margin-right: 5px;
            }
        }
    }

    .merchant-orders-page__data-cell {
        &.payment {
            width: 100px;
        }

        .actions-container {
            display: flex;

            .swiipe-spinner-container {
                flex: 1;
                flex-basis: 0;
                min-width: 0;
                max-width: 90px;

                &:nth-child(2) {
                    margin-left: 10px;
                }
            }

            button {
                background: transparent !important;
                font-size: 14px;

                color: #797676;
                border-color: #797676;
            }

            .capture-button {
                color: #23638e;
                border-color: #23638e;
            }
        }

        .pl-order-id-clickable {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 1130px) {
        .actions-container {
            .swiipe-spinner-container {
                max-width: 76px !important;
            }
        }
    }

    @media screen and (max-width: 340px) {
        padding: 15px 5px !important;
    }
}

.merchant-orders-export-container {
    display: flex;
    justify-content: flex-end;
    margin: 0 20px;

    .export-text {
        align-self: center;
        margin-right: 15px;
        font-size: 14px;
    }

    select {
        flex: 0 1 auto;
        width: auto;
        margin-right: 20px;
    }

    button {
        flex: 0 1 auto;
        width: auto;
    }
}
