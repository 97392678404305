.merchant-dashboard-cell-title {
    margin-bottom: 5px;
    margin-left: 20px;

    .title {
        display: inline-block;
        position: relative;
        font-weight: 700;
    }
    .subtitle {
        font-size: 12px;
        font-style: italic;
    }

    &.disabled .title,
    &.disabled .subtitle {
        opacity: 0.3;
    }

    .data-compare {
        position: absolute;
        right: -10px;
        transform: translateX(100%);
        display: flex;
        align-items: center;
        height: 100%;
        top: 0;
    }

    .title-icon {
        position: absolute;
        top: -10px;
        right: -30px;

        img {
            width: 25px;
        }
    }
}
