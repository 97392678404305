.goback-container {
    span {
        color: #555555;
        cursor: pointer;
        font-size: 12px;
    }

    &.goback-container__no-bot-margin {
        margin-bottom: 0 !important;
    }
}
