// .swish-onboarding-container {
.swish-form-head {
    text-align: center;
    margin: 20px 0 30px 0;

    .swish-logo {
        max-width: 160px;
        margin-right: 5px;
    }

    .swiipe-payments {
        width: 170px;
    }
}

.swish-grey-textbox {
    text-align: left;
    background-color: #f2f2f2;
    padding: 15px 25px 15px 25px;
    font-size: 15px;
    font-family: "Open Sans";
    color: #1a496d;
    width: 90%;
    margin: 0 auto;
}

.swish-sub-text {
    color: #797979;
    text-align: center;
    font-size: 1.1em;
    margin-top: 8px;
}

@media screen and (max-width: 475px) {
    .swish-grey-textbox {
        width: 100%;
    }
}
