.icon {
    background: url("../images/icons/personal/personal-blue.png");
    background-size: 30px;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    display: block;

    &.icon-exclamation-mark {
        background-size: 16px !important;
        height: 18px;
        width: 9px;
        background-repeat: no-repeat !important;
        background-position-x: center !important;
        display: inline-block;
        background: url("../images/icons/exclamation-mark.png");
    }
}
