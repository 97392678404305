.table-filter-box {
    display: flex;
    background: #f2f2f2;
    align-items: center;
    padding: 10px 30px 10px 15px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .table-filter-box__filter-options {
        display: flex;
    }

    .table-filter-box__filter-text {
        color: #7f7f7f;
        white-space: nowrap;
        img {
            margin-right: 5px;
        }
    }

    .table-filter-box__close-box {
        position: absolute;
        right: 15px;

        img {
            cursor: pointer;
        }
    }

    @media screen and (max-width: 1120px) {
        .table-filter-box__filter-options {
            flex-wrap: wrap;
            justify-content: center;
        }

        .table-filter-box__filter-text {
            margin-bottom: 5px;

            span {
                display: none;
            }
        }

        .table-filter-box__close-box {
            margin-bottom: 5px;
        }
    }

    @media screen and (max-width: 624px) {
        margin-top: 5px;
    }
}
