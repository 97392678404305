.anyday-onboarding-container {
    .anyday-form-head {
        text-align: center;
        margin: 30px 0;

        .anyday-logo {
            width: 140px;
        }
    }

    .hint-disclaimer {
        margin: 0 10%;

        .toggle {
            cursor: pointer;
            display: block;
            text-decoration: underline;
            text-align: center;
            font-weight: 300;
            font-size: 13px;
            margin-top: 5px;
        }

        .list-container {
            padding: 0 7%;

            .heading {
                font-size: 15px;
            }
        }
    }

    .hint-top {
        background: #f2f2f2;
        padding: 20px;
        font-size: 15px;
        margin: 0 10%;
        margin-bottom: 10px !important;

        .head {
            display: block;
            text-align: center;
        }
    }

    .anyday-info {
        font-weight: 600;
        font-size: 1.25em;
        color: #1a496d;
    }

    .anyday-form {
        margin-top: 10px;
        padding: 0 15%;

        textarea {
            resize: none;
            font-weight: 300;
            background-color: #f2f2f27f;
            border: unset;
        }

        .submit-btn {
            //max-width: 280px;
            margin: 0 auto;
        }
    }

    .anyday-edit-container {
        .anyday-edit-form {
            max-width: 400px;
            margin: 0 auto;
        }

        .edit-mode-text {
            font-size: 1.2em;
        }

        .checkbox-text {
            margin-bottom: 5px;

            a {
                color: #1a496d;
                font-size: 14px;
            }
        }

        .close-btn {
            display: block;
            max-width: 250px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 685px) {
        .anyday-form {
            padding: 0 10%;
        }
    }

    @media screen and (max-width: 450px) {
        .hint-disclaimer,
        .hint-top {
            margin: 0;
        }

        .anyday-form {
            padding: 0 5%;
        }
    }

    @media screen and (max-width: 380px) {
        .anyday-form {
            padding: 0;
        }
    }
}
