@import "../../scss/partials/all";

#swiipe-psp-onboarding-container {
    margin: 0 100px;

    .onboarding-container {
        padding: 30px 0;
        border-radius: 0px;
    }

    .text-regular {
        color: #797676;
        font-size: 1.22em;
    }

    .text-normal {
        font-size: 1.4em;
        color: #797979;
        font-weight: 300;

        &.text-larger {
            font-size: 1.35em;
        }
    }

    .activate-container {
        max-width: 345px;
        margin: 0 auto;
    }

    .onboarding-option {
        background-color: #f2f2f280;
        color: #1a496dfa;
        flex-grow: 1;
        padding: 20px 15px;
        margin: 15px 15px;

        .price {
            text-align: center;
            font-size: 1.5em;
            padding: 20px 0;

            span {
                font-size: 2em;
            }
        }
    }
}

@media screen and (max-width: 745px) {
    #swiipe-psp-onboarding-container {
        margin: initial;
    }
}
