@import "../../../../src/scss/partials/_all.scss";
$switch-active: #bada55;
$switch-text-active: #258e23;
$switch-disabled: #bfbfbf;

.organization-users.infobox {
    .edituser-box {
        color: $blue-main;
        .name-container {
        }
    }

    .inner-info-container {
        .mail-container,
        .phone-container {
            font-weight: 300;
        }
    }

    .switch-container {
        display: flex;
        align-items: center;
        position: absolute;
        right: 15px;
        bottom: 0;

        @media screen and (max-width: $breakpoint-mobile) {
            position: initial;
            justify-content: flex-end;
        }

        .switch-text {
            font-size: 0.85em;
            font-weight: 300;
            font-style: italic;
            color: $gray-font;
            margin-right: 10px;

            &.active {
                color: $switch-text-active;
            }
        }
    }
}
