.table-item-details-header {
    padding: 50px !important;

    @media screen and (max-width: 455px) {
        padding: 20px !important;
    }
}

.table-item-details {
    padding: 50px !important;
    min-height: 300px;

    .table-item-details__content-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .table-item-details__top-right {
        width: 500px;
        margin-left: 50px;
        color: #1a496d;
    }

    .table-item-details__top-right-title-row-outer {
        margin-bottom: 30px;
    }

    .table-item-details__top-right-title-row {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .table-item-details__top-right-row {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .table-item-details__top-left {
        width: 400px;
    }

    @media screen and (max-width: 870px) {
        .table-item-details__content-top {
            flex-direction: column;

            .table-item-details__top-right {
                margin-left: 0;
                margin-top: 20px;
                width: 400px;
            }

            .table-item-details__top-left {
                width: 400px;
            }
        }
    }

    @media screen and (max-width: 870px) {
        .table-item-details__content-top {
            .table-item-details__top-right {
                width: 100%;
            }

            .table-item-details__top-left {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 455px) {
        padding: 15px !important;
    }
}

.table-item-details-footer {
    display: flex;
    padding: 10px 20px;
    .table-item-details-footer__previous {
        flex: 0 0 auto;
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
    }
    .table-item-details-footer__spacer {
        flex: 1 1 0;
    }
    .table-item-details-footer__next {
        flex: 0 0 auto;
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
    }

    @media screen and (max-width: 870px) {
        display: block;
        padding-bottom: 30px;
        .table-item-details-footer__spacer {
            display: none;
        }
        .table-item-details-footer__previous {
            float: left;
        }
        .table-item-details-footer__next {
            float: right;
        }
    }
}
