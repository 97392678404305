#platform-select-comp {
    .heading {
        flex: 1 0 100%;
        font-weight: 300;
        font-size: 1.1em;
    }

    .platform-select {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .ddl-comp {
            flex: 1;
        }
    }

    .platform-logo {
        max-width: 140px;
        margin: auto 10px;
    }
}
