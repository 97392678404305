.pw-requirement {
    &--blue-bubble {
        background-color: rgba(225, 238, 250, 0.5);
        padding: 12px 20px 0 20px;
        position: relative;
        border-radius: 10px;

        .dialog-title,
        .dialog-text li {
            color: #1a496d !important;
        }

        .pw-requirement__arrow {
            position: absolute;
            left: 10px;
            top: -10px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-bottom: 10px solid rgba(225, 238, 250, 0.5);
        }
    }
}
