@import "../../scss/partials/_colors.scss";

.merchant-dashboard-cell-container {
    height: 100%;
    background-color: white;
    padding: 20px;

    .positive {
        color: $dark-blue !important;
    }

    .negative {
        color: $dark-red !important;
    }

    position: relative;
    .disabled-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #ffffffb3;
        z-index: 1;
        top: 0;
        left: 0;

        &.values {
            height: calc(100% - 65px);
            bottom: 0;
            top: unset;
        }
    }
}
