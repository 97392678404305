.textarea-with-copybtn {
    span.fixed-label {
        font-size: 1.2em;
    }

    .inputs {
        display: flex;
        margin-top: 3px;
    }

    .inputs.right {
        flex-direction: row;

        #copy-to-clipboard-btn {
            margin-left: 10px;
            width: fit-content;
            padding: 0 2em 0 2em;
            margin-left: 15px;
        }
    }

    .inputs.below {
        flex-direction: column;

        #copy-to-clipboard-btn {
            margin-top: 5px;
        }
    }

    a {
        color: #23638e;
    }

    @media only screen and (max-width: 808px) {
        .inputs.right {
            flex-wrap: wrap;

            #copy-to-clipboard-btn {
                margin: 5px 0 0 0;
                width: 100%;
            }
        }
    }
}
