@import "../form/clearhaus/ClearhausOnboardingForm.scss";

.clearhaus-update-info {
    &--inside-form {
        position: absolute;
        bottom: 0;
    
        .clearhaus-update-info__main-btn {
            margin-left: $clearhaus-base-fields-width + 15px;
        }
    
        @include clearhaus-breakpoint-laptop {
            position: initial;
            .clearhaus-update-info__main-btn {
                margin-left: 0;
            }
        }
    }
}