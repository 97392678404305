@import "../../../scss/partials/all.scss";

#merchant-signup {
    &.center-form {
        width: $center-form-width-desktop-merchant !important;

        @media screen and (max-width: $breakpoint-mobile-m1) {
            width: 100% !important;
        }

        @media screen and (max-width: $breakpoint-tables) {
            width: 100% !important;
            padding: 2rem 3rem !important;
        }
    }

    .form-head {
        #merchant-heading.form-head-heading {
            span,
            img {
                display: inline-block;
            }

            img.merchant-head-logo {
                height: 34px;
                width: auto;
            }
        }
    }

    .form-check {
        p.swiipe-accept-terms {
            text-align: left !important;
        }
    }

    #merchant-signup-btn {
        padding: 0px;
        border: none;
        opacity: 1;
        cursor: pointer;
        background-color: unset !important;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    #back-btn {
        width: 30%;
    }

    .accept-terms {
        font-size: 11px;
        color: #585858;
    }

    ul {
        padding-left: 15px;
    }

    .swiipe-form-footer {
        margin-top: 0 !important;
    }
}
