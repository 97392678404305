.checkbox-hint {
    position: absolute;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    font-weight: 300;
    padding: 5px 10px;
    border-radius: 4px;
    left: -25px;
    margin-top: -57px;

    -webkit-box-shadow: 4px 4px 4px -1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 4px -1px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 4px -1px rgba(34, 60, 80, 0.2);

    &::after,
    &::before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &::after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 7px;
        margin-left: -7px;
    }

    &::before {
        border-color: rgba(187, 187, 187, 0);
        border-top-color: #bbbbbb;
        border-width: 8px;
        margin-left: -8px;
    }
}
