@import "../../scss//partials/variables";

$break-summary-one-column: $breakpoint-tables;

.merchant-offer-page {
    background-color: white;

    .merchant-offer-page__content-spinner {
        &--loading {
            max-height: 200px;
        }
    }

    .merchant-offer-page__nav-button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 120px;
        margin-bottom: 50px;
        .merchant-offer-page__nav-button {
            width: 300px;
            float: right;
            clear: both;
            margin-right: 10px;
        }

        @media (max-width: $break-summary-one-column) {
            margin-top: 50px;
            justify-content: center;
            .merchant-offer-page__nav-button {
                max-width: inherit;
                margin: 0 15px;
            }
        }

        .merchant-offer-page__nav-button-inner {
            height: 54px;
            font-size: 16px;
        }
    }
}
