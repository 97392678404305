.filter-checkbox-container {
    input[type="checkbox"] {
        margin-top: 3px;
        margin-right: 6px;
        position: static;
        margin-left: 0;
    }

    label {
        cursor: pointer;
        margin-bottom: 5px;
    }
}
