#release-note-display {
    font-size: 1.2em;
    position: relative;

    .version {
        font-weight: 600;
        margin-bottom: 0;
    }

    .date {
        font-size: 0.9em;
        font-weight: 300;
    }
}
