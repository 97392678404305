@import "../ClearhausOnboardingForm.scss";

.clearhaus-person-fields {
    .clearhaus-person-fields__file-upload {
        display: flex;

        .clearhaus-person-fields__upload-fields {

            .clearhaus-person-fields__explanation-above-input {
                font-size: 16px;
                color: #555555;

                span {
                    font-style: italic;
                    color: #797979;
                }
            }

            .clearhaus-person-fields__file-upload {
                font-size: 13px;
            }
        }

        .clearhaus-person-fields__upload-hint {
            flex-grow: 1;

            .clearhaus-person-fields__hint {
                float: right;
                margin-right: $clearhaus-hints-margin-right;
                margin-bottom: 1.5em;

                .clearhaus-person-fields__hint-title {
                    width: $clearhaus-hints-title-width;
                }
            }
        }
    }

    .clearhaus-person-fields__row-city-postcode {
        max-width: $clearhaus-base-fields-width + 30px;
    }

    .clearhaus-person-fields__error-messages {
        margin-left: 0 !important;
    }

    @include clearhaus-hint-breakpoint-laptop {
        .clearhaus-person-fields__file-upload {
            flex-direction: column;

            .clearhaus-person-fields__upload-hint {
                .clearhaus-person-fields__hint {
                    float: unset;
                    margin: 15px 0 0 0;
                    max-width: $clearhaus-base-fields-width;
 
                    .clearhaus-person-fields__hint-title {
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    @include clearhaus-breakpoint-tablet {
        .clearhaus-person-fields__row-city-postcode {
            & > div:first-child {
                padding-right: 0 !important;
            }
        }

    }

    @media screen and (max-width: 767px) {
        .clearhaus-person-fields__row-city-postcode {
            & > div:first-child {
                padding-right: 15px !important;
            }
        }
    }
}