.swiipe-close-box {
    background-color: white;
    margin: 20px 0;
    padding: 15px;
    position: relative;

    .close-cross {
        cursor: pointer;
        position: absolute;
        right: 30px;
        top: 30px;
        transition: 0.5s ease;

        &:hover {
            transform: scale(1.1);
        }
    }
}
