.modal-company-already-registered {
    .modal-title {
        .close-cross {
            top: 15px;
        }
    }

    .content-container {
        font-size: 16px;
        color: #113251;
    }

    .modal-footer {
        text-align: center;
        font-size: 13px;
        color: #797979;
    }

    span {
        text-decoration: underline;
        cursor: pointer;
    }
}
