.tabs-box {
    display: flex;

    .tabs {
        display: flex;
        flex-direction: column;
        font-size: 1.15em;
        text-align: center;
        padding: 0 15px 0 0;
        margin: 0 15px 0 0;
        border-right: 1px solid #e3e3e3;

        span {
            cursor: pointer;
            padding: 10px 5px;
            border-bottom: 1px solid #e3e3e3;
            color: #97999a;

            &.active {
                color: #1a496d;
                font-weight: 600;
            }

            &:last-child {
                border-bottom: unset;
            }
        }
    }

    .content {
        flex-basis: 100%;
    }
}

@media screen and (max-width: 450px) {
    .tabs-box {
        flex-direction: column;

        .tabs {
            padding: 0;
            margin: 0;
            border-right: unset;

            span {
                &:last-child {
                    border-bottom: 1px solid #e3e3e3;
                }
            }
        }
    }
}
