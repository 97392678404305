.merchant-dashboard-circle-diagram {
    position: relative;

    .diagram {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
