@import "../../scss/partials/_all.scss";

$breakpoint-newline: 830px;

#partner-relations-page {
    .swiipe-spinner-container {
        display: block;
    }

    .ecommerce-account-box {
        .icon-field {
            width: 5rem;
        }

        .separator-left {
            padding-left: 1rem;
            border-left: 10px solid $gray-border;
            max-width: 445px;

            &.active {
                border-color: $blue-second;
            }
        }

        .merchant-name {
            font-size: 1.15em;
        }

        .action-button {
            height: 35px;
            width: 130px;
            font-weight: 400;
            text-transform: none;
        }

        .text-above-button {
            color: $red-error;
        }

        .action-btn-text-container {
            text-align: center;
        }

        .request-btn {
            width: 100px;
            height: 30px;
            padding: 0;
            margin: 0 auto;
        }

        @media screen and (max-width: $breakpoint-newline) {
            flex-wrap: wrap;

            .icon-field {
                flex-basis: 10%;
                width: auto;
            }

            .separator-left {
                flex-basis: 75% !important;
            }

            .action-button-box {
                flex-basis: 100%;
                margin-top: 1rem;
            }

            .action-btn-text-container {
                float: right;
            }
        }
    }
}
