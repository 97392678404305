.swiipe-btn {
    &.login {
        img {
            width: 100%;
            height: auto;
        }
    }
    &.reset_password {
        padding: 0px;
        border: none;
        opacity: 1;
        cursor: pointer;
        background-color: unset !important;
        height: auto;

        img {
            width: 100%;
        }
    }
}
