.sw-credit-card-type-logo {
    display: inline-block;
    &--has-subscription {
        position: relative;

        .sw-credit-card-type-logo__subscription-frame {
            margin: 0 !important;
            padding: 0 !important;
        }

        .sw-credit-card-type-logo__card-logo {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
}
