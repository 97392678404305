.modal-ga-onboarding-error {
    .modal-title {
        color: #990000;
        font-size: 17px;
        font-weight: 400;
        padding: 0.4rem;
    }

    .content-container {
        padding: 0 30px 0 30px;

        .buttons {
            display: flex;
            justify-content: space-between;

            &.centered {
                justify-content: center;
            }

            button {
                max-width: 180px;
            }
        }

        .alert {
            color: #333333;
            font-size: 14px;

            .form-check-label {
                margin-left: 20px;
            }
        }
    }

    .modal-footer {
        .text-btn {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 485px) {
        .content-container {
            .buttons {
                flex-direction: column;
                align-items: center;

                button {
                    max-width: 250px;
                    
                    &:nth-child(2) {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
