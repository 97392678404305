@import "../../scss/partials/_all.scss";

.infobox-add-container {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
        margin: 0 !important;
    }

    .label {
        text-transform: uppercase;
        margin-left: 5px;
        font-size: 0.8em;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        .label {
            font-size: 0.6em;
        }
    }
}
