﻿/* Colors */
$blue-logo: #0e3251;
$blue-main: #1a496d;
$blue-header: #23638e;
$blue-main-selected: #496e8a;
$blue-second: #3b72a9;
$blue-logo-dark: #071d30;

$dark-blue: #06324a;
$dark-red: #6a2733;
$gray-font: #797676;

$orange-logo: #f16624;
$orange-warning: #f16624;
$green-menu: #e0e668;
$green-primary: #a5cd39;
$yellow-selected: #e0e668;
$white-menu: f2f2f2;
$grey-background: #f6f6f6;
$gray-border: #cccccc;
$gray-font-dark: #4d4d4d;
$red-error: #cc0000;
$red-cancel: #dd0000;
$gray-light: #e5e5e5;
$gray-overlay: #2f4f4f;
$gray-background: #f2f2f2;
$gray-light: #d7d7d7;
$blue-link-hover: #7c94a6;
