@import "../../scss/partials/_all.scss";

$gray-confirmdelete: #f9f9f9;

.alert-confirmdelete {
    border: none;
    background: $gray-confirmdelete;

    .confirmdelete-heading {
        color: $red-cancel;
    }

    .confirmdelete-text {
        color: $blue-main;
    }
}
