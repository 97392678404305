#resend-email-box {
    width: 100%;
    margin-top: 10px;
    padding-left: 20px;

    .resend-btn {
        width: 100px;
        height: 38px;
        padding: 0;
        float: right;
        margin: 0 35px;
    }

    .swiipe-spinner-container {
        width: 100%;
        .spinner {
            top: 35px;
            left: 25px;
        }
    }

    @media screen and (max-width: 430px) {
        .resend-btn {
            width: 70px;
            margin: 0;
            float: left;
        }
    }
}
