.swiipe-files-list {
    margin-bottom: 20px;

    &.mb-smaller {
        margin-bottom: 10px;
    }

    span {
        display: block;
        margin-top: 5px;
    }

    span.file:before {
        content: url("../../images/icons/file-icon.svg");
        display: inline-block;
        width: 22px;
        vertical-align: middle;
    }

    .file-option {
        position: relative;

        .delete-file-btn {
            color: #356497;
            font-size: 1.5em;
            margin-left: 5px;
            margin-top: 6px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 0;
            line-height: 10px;
        }

        &.red-color {
            color: #ca0000;

            span.file:before {
                content: url("../../images/icons/file-icon-red.svg");
            }
        }
    }

    span.error {
        color: #ca0000;
    }
}
