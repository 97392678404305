.checkbox-spinner {
    img.spinner {
        right: unset;
    }

    label {
        margin-bottom: unset;
    }

    .checkbox-label__indented {
        padding-left: 1.25rem;
    }
}