#switchservices-page {
    .webshop-select {
        display: flex;
    }

    .platform-logo {
        max-width: 100px;
        margin: auto 10px;
    }

    .services-switch {
        background-color: #f2f2f280;
        margin: 30px 0 0 0;
        padding: 15px 40px;
        font-size: 1.3em;
    }

    .submit-container {
        display: flex;

        .swiipe-spinner-container {
            width: 100%;
        }
    }

    .warning {
        font-size: 12px;
        color: orange;
    }

    .swiipe-plan-missing {
        color: red;
        padding-bottom: 20px;
    }

    .switchservices-page__mpo-onboard-btn {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .switchservices-page__mpo-onboard-intro {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .switchservices-page__info-data-value--small {
        font-size: 12px;
    }
}
