.mobilepay-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
        font-family: "Open Sans";
        color: #1a496d;
        font-size: 16px;
    }
    b {
        font-family: "Open Sans";
        color: #1a496d;
        font-size: 16px;
    }

    .mobilepay-text2 {
        width: 90%;
        margin: 10px 0 10px 0;

        .text-header {
            width: 100%;
            margin-bottom: 1.5em !important;

            a {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .text-footer {
            width: 100%;
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;
            font-size: 0.85em;
        }

        .list-container {
            font-size: 1.2em;
            margin: 0 auto;
        }
    }

    .mobilepayonline-onboarding-form {
        width: 80%;
        margin: 0 auto;

        .current-logo-box {
            font-size: 1.1em;
            background-color: #f2f2f2;
            padding: 14px;

            a {
                font-weight: 300;
                color: #1a496d;
                overflow-wrap: anywhere;
            }
        }

        .block-heading {
            margin-bottom: 0.5em !important;
            font-weight: 600;
            font-size: 1.2em;
            color: #1a496d;
        }

        .btn-adder {
            margin-top: 10px !important;
            color: #23638e;
            font-weight: 600;
        }

        .text-fileupload {
            text-align: left;
            font-size: 1.1em;
            color: #767679;

            b {
                color: #767679;
                font-size: 1em;
            }
        }

        .swiipe-files-list {
            text-align: left;
        }

        .submit-btn {
            margin: 0 50px;
        }

        .swiipe-accept-terms {
            font-size: 0.9em;
            max-width: unset;
            color: #7f7f7f;

            margin: 0 auto;
        }

        .alert-danger {
            text-align: left;
        }

        .form-check {
            text-align: left;
        }
    }

    .mobilepay-back-link a {
        color: #23638e;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .mobilepay-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 25px 15px 25px;

        p {
            margin-bottom: 0px !important;
            margin-left: 5px;
        }
        img {
            height: auto;
            width: 40px;
        }

        img:nth-child(2) {
            width: 100px;
        }
    }

    .webshop-inputs {
        margin-bottom: 30px;
        border-top: 1px solid #76767930;
    }

    .webshop-inputs:nth-child(1) {
        border-top: none;
    }

    .mobilepay-text5 {
        color: #797979;
        font-weight: 300;
        font-size: 1.3em;

        b {
            color: #797979;
        }
    }

    .upload-logo-text {
        font-size: 1.6em;
        color: #1a496d;
    }

    .upload-logo-text-2 {
        color: #1a496d;
        font-size: 14px;
        max-width: 500px;

        b {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 460px) {
        .mobilepayonline-onboarding-form {
            width: 100%;
        }
    }
}
