.tx-action-error-modal {
    .report-btn {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 0.85em;
        bottom: -45px;
        text-decoration: underline;
        cursor: pointer;
        color: #808083;
    }

    .input-group {
        textarea {
            font-weight: 500;
        }
    }

    .modal-title {
        color: #910012;
        font-size: 17px;
        font-weight: 400;
        padding: 0.4rem;
    }

    @media screen and (max-width: 350px) {
        .report-btn {
            margin-left: unset;
            margin-right: unset;
            left: unset;
            right: unset;
        }
    }
}
