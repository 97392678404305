@import "../../scss/partials/all";

#webshop-select-comp {
    .webshop-select {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .heading {
            flex: 1 0 100%;
            font-weight: 300;
            font-size: 1.1em;
        }

        select {
            flex: 1;
        }
    }

    .platform-logo {
        max-width: 140px;
        margin: auto 10px;
    }

    .hint-webshop-select {
        margin-top: 1rem;
        background: #f2f2f2;
        text-align: center;
        padding: 20px 10px;

        span {
            max-width: 560px;
        }
    }

    @media screen and (max-width: $breakpoint-tables) {
        .webshop-select {
            .heading {
                order: 1;
                flex: 1 0 30%;
            }

            img {
                order: 2;
                flex: 1 0 70%;
            }

            select {
                order: 3;
                flex: 1 0 100%;
            }
        }
    }
}
